import AudioEncodingsTable from "@/components/debug/AudioEncodingsTable";
import ItemFilesTable from "@/components/debug/ItemFilesTable";
import ItemInfoTable from "@/components/debug/ItemInfoTable";
import ItemLinksTable from "@/components/debug/ItemLinksTable";
import TranscriptionsTable from "@/components/debug/TranscriptionsTable";
import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { AppContext } from "@/models/AppStateProvider";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import "regenerator-runtime/runtime";
import { item as itemTable, feed as feedTable } from "@/db/schema";
import { eq } from "drizzle-orm";

export default function DebugItemId() {
  const params = useParams();

  const workspaceId = params?.workspaceId as string;
  const feedId = params?.feedId as string;
  const feedItemId = params?.itemId as string;

  const { flags } = React.useContext(AppContext);

  const { rows: item } = useDrizzleQuery(
    db.query.item.findFirst({ where: eq(itemTable.id, feedItemId) }),
  );

  const { rows: currentFeed } = useDrizzleQuery(
    db.query.feed.findFirst({ where: eq(feedTable.id, feedId) }),
  );

  if (!currentFeed) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Feed Not Found
      </Stack>
    );
  }

  if (!item) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Item Not Found
      </Stack>
    );
  }

  if (!flags.debugMode) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Debug Mode Disabled
      </Stack>
    );
  }

  const isProd = window.location.hostname?.includes("storyboard.com");
  const isLocal = window.location.hostname?.includes("localhost");
  const stage = isProd
    ? null
    : isLocal
      ? "dev"
      : window.location.hostname?.split(".")[0];

  const sbAdminUrl = isProd
    ? "sbadmin.storyboard.com"
    : `${stage}.sbadmin.storyboardtesting.com`;

  return (
    <Stack spacing={2}>
      <Stack
        width={"100%"}
        direction="row"
        alignSelf={"center"}
        alignContent={"center"}
        pt={3}
        spacing={2}
      >
        <Link
          to={`https://${sbAdminUrl}/content/${item?.contentId}`}
          target="_blank"
        >
          SbAdmin
        </Link>
      </Stack>
      <Typography variant="h4">Item Info</Typography>
      <ItemInfoTable item={item} />
      <Typography variant="h4">Files</Typography>
      <ItemFilesTable item={item} />
      <Typography variant="h4">Links</Typography>
      <ItemLinksTable item={item} />
      <Typography variant="h4">Audio</Typography>
      <AudioEncodingsTable item={item} />
      <Typography variant="h4"> Transcriptions </Typography>
      <TranscriptionsTable item={item} />
    </Stack>
  );
}
