import SearchFilter from "@/components/SearchFilter";
import { FullInput } from "@/components/Utils";
import ChannelEmptyState from "@/components/WorkspaceChannelList/ChannelEmptyState";
import ChannelTypes from "@/components/WorkspaceChannelList/ChannelTypes";
import NewWorkspaceChannelItem from "@/components/WorkspaceChannelList/WorkspaceChannelLinkItem";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import Locator from "@/locator";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { ChannelList, fetchChannelList } from "@/models/commonQueries";
import AddIcon from "@mui/icons-material/Add";
import CancelRounded from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Tooltip } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { logger } from '@/utils/logging';

export default function WorkspaceChannelListContainer({
  notificationsBanner,
}: { notificationsBanner: boolean }) {
  const { toggleChannelModal, userReadOnlyMode } = useContext(UxContext);
  const { isWorkspaceLimitedMember, currentWorkspaceId } =
    useContext(WorkspaceContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const { workspaceMembershipId } = useContext(LiveQueryContext);
  const limitedMember = isWorkspaceLimitedMember();
  const feedId = currentFeedId;
  const { myAccount } = useContext(MyAccountContext);

  const [channelOrder, saveChannelOrder] = useLocalStorage(
    "channel-sort-order",
    "driver-activity",
  );

  const [channelType, saveChannelType] = useLocalStorage(
    "channel-sort-type",
    "my-channels",
  );

  const [activeChannelType, setActiveChannelType] =
    useState<string>(channelType);

  const [searchValue, setSearchValue] = useState<string>("");

  const { rows: channels } = useDrizzleSelect(
    fetchChannelList({
      channelOrder,
      channelType,
      workspaceId: currentWorkspaceId,
      workspaceMembershipId,
      searchValue,
      limitedMember,
      myAccount,
    }),
  ) as { rows: ChannelList[] };

  useEffect(() => {
    logger({ channels });
  }, [channels]);

  const unreadCount = channels?.filter(
    (channel) => channel.unread && channel.joined,
  ).length;

  const [searchInputFullWidth, setSearchInputFullWidth] =
    useState<boolean>(false);

  const handleInputFocus = (value: boolean) => {
    if (value === true && !searchInputFullWidth) {
      setSearchInputFullWidth(() => true);
    } else if (value === false && searchValue.length === 0) {
      setSearchInputFullWidth(() => false);
    }
  };

  return (
    <Box
      className={`
        workspace-channel-list-container
        ${notificationsBanner ? "has-banner" : ""}
        ${userReadOnlyMode ? "read-only-mode" : ""}
      `}
    >
      <Box
        className={`
            channel-search-container
            ${searchInputFullWidth ? "active" : ""}
        `}
      >
        <Box
          className={`channel-search ${limitedMember ? "limited-member" : ""}`}
        >
          <FullInput
            onFocus={() => handleInputFocus(true)}
            onBlur={() => handleInputFocus(false)}
            value={searchValue}
            inputProps={{
              placeholder: "Search",
            }}
            formControlProps={{
              sx: {
                "& .MuiInputBase-input": {
                  paddingLeft: "1rem !important",
                },
              },
            }}
            callback={(e) => {
              setSearchValue(e.target.value);
              setActiveChannelType(() =>
                limitedMember ? "my-channels" : "all-channels",
              );
              saveChannelType(() => "all-channels");
            }}
          />
          <Box className="channel-search-clear-button">
            {searchValue?.length ? (
              <button
                type="button"
                aria-label={Locator.workspaceNav.channels.find.clear}
                onClick={() => {
                  setSearchValue(() => "");
                  setSearchInputFullWidth(() => false);
                }}
              >
                <CancelRounded />
              </button>
            ) : (
              <div>
                <SearchIcon
                  sx={{ color: searchValue?.length > 0 ? "#fff" : "#ccc" }}
                />
              </div>
            )}
          </Box>
        </Box>
        <Box
          className={`flex channel-options space-x-0.5 ${
            limitedMember ? "limited-member" : ""
          }`}
        >
          {!limitedMember && (
            <Box className="channel-options-button">
              <Tooltip title="Create new channel">
                <button
                  type="button"
                  onClick={toggleChannelModal}
                  aria-label={Locator.workspaceNav.channels.create}
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </Box>
          )}
          <Box className="channel-options-button">
            <SearchFilter
              channelOrder={channelOrder}
              changeChannelSortOrder={(data) => saveChannelOrder(data)}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb: 2, px: 1 }}>
        <ChannelTypes
          limitedMember={limitedMember}
          unreadCount={unreadCount}
          changeChannelType={(value) => {
            setActiveChannelType(() => value);
            saveChannelType(() => value);
          }}
          activeChannelType={activeChannelType}
        />
      </Box>
      <Box
        className="workspace-channel-list-items"
        aria-label={Locator.workspaceNav.channels.list.container}
      >
        {channels?.length > 0 ? (
          <>
            {channels.map((channel) => (
              <NewWorkspaceChannelItem
                readOnlyMode={userReadOnlyMode}
                key={channel.id}
                active={channel.id === feedId}
                channel={channel}
                workspaceMembershipId={workspaceMembershipId}
              />
            ))}
          </>
        ) : (
          <ChannelEmptyState
            limitedMember={limitedMember}
            hasSearch={searchValue?.length > 0}
            channelType={channelType}
            changeChannelType={(value) => {
              setActiveChannelType(value);
              saveChannelType(value);
            }}
          />
        )}
      </Box>
    </Box>
  );
}