import DmModal from "@/components/DmModal";
import FeedHeader from "@/components/FeedHeader";
import PersonMenu from "@/components/Icons/PersonMenu";
import LanguageDropdown from "@/components/LanguageDropdown";
import LoadingScreen from "@/components/LoadingScreen";
import MainContent from "@/components/MainContent";
import MembersDrawer from "@/components/MembersDrawer";
import NoValidWorkspacesModal from "@/components/NoValidWorkspacesModal";
import SBAppBar from "@/components/SBAppBar";
import ScheduleMessageModal from "@/components/Scheduling/ScheduleMessageModal";
import SearchContainer from "@/components/Search/SearchContainer";
import SqlEditor from "@/components/SqlEditor";
import UserFeedbackModal from "@/components/UserFeedbackModal";
import InviteToWorkspaceModal from "@/components/Workspace/InviteToWorkspaceModal";
import WorkspaceDrawer from "@/components/Workspace/WorkspaceDrawer";
import useLocationChange from "@/hooks/useLocationChange";
import Locator from "@/locator";
import { AppContext } from "@/models/AppStateProvider";
import { AudioAppContext } from "@/models/AudioAppContextProvider";
import { DataContext } from "@/models/DataProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UnreadsContext } from "@/models/UnreadsContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { MAIN_DRAWER_WIDTH, MEMBERS_DRAWER_WIDTH } from "@/utils";
import * as Icons from "@mui/icons-material";
import TerminalIcon from "@mui/icons-material/Terminal";
import {
  Box,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import Bowser from "bowser";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useContext, useEffect, useRef } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import UseHandsFreeStatus from "@/hooks/useHandsFreeStatus";

export default function Workspace() {
  const theme = useTheme();
  const { ampli } = useContext(TrackingContext);
  const { flags } = React.useContext(AppContext);
  const { unreadFeedIds } = React.useContext(UnreadsContext);
  const browser = Bowser.getParser(window.navigator.userAgent);
  const searchKey =
    browser.getOSName().toLowerCase() === "macos" ? "Cmd" : "Ctrl";
  const navigate = useNavigate();
  const { bootstrapComplete, firstBootWorkspaceId } =
    React.useContext(DataContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const params = useParams();
  const urlWorkspaceId = params.workspaceId;
  const { workspaceMemberships, currentWorkspaceId, workspaces } =
    React.useContext(WorkspaceContext);
  const {
    setUserFeedbackModalOpen,
    setGlobalSearchOpen,
    setToolbarSize,
    globalSearchOpen,
  } = React.useContext(UxContext);
  const { allowNewWorkspaceCreation, globalSearch, autoJoinModalOptions } =
    useFlags();
  const showNoValidWorkspacesModal =
    workspaceMemberships?.length === 0 && allowNewWorkspaceCreation === false;

  const feedId = currentFeedId;
  const workspaceId = currentWorkspaceId;

  useEffect(() => {
    if (globalSearchOpen) {
      ampli.searchClick({ workspaceId });
    }
  }, [globalSearchOpen, workspaceId, ampli.searchClick]);

  const { playerPlaying, pauseItem } = React.useContext(AudioAppContext);

  const { isSmUp, leftNavOpen, toggleLeftNav, rightNavOpen, toggleRightNav } =
    React.useContext(UxContext);
  const [url, setUrl] = React.useState<string | null>(null);

  useLocationChange((location) => {
    const baseUrl = window.location.origin;
    // Don't Stop Queue -> If dsq is present in the URL, don't reset the queue
    const dsq =
      new URLSearchParams(window?.location?.search).get("dsq") ?? null;
    setUrl(baseUrl + location.pathname);
    if (playerPlaying && !dsq) {
      pauseItem();
    }
  });

  const drawerStyles = {
    width: isSmUp ? MAIN_DRAWER_WIDTH : "100%",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: isSmUp ? MAIN_DRAWER_WIDTH : "100%",
      boxSizing: "border-box",
    },
  };

  const memberDrawerStyles = {
    width: isSmUp ? MEMBERS_DRAWER_WIDTH : "100%",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: isSmUp ? MEMBERS_DRAWER_WIDTH : "100%",
      boxSizing: "border-box",
    },
  };

  React.useEffect(() => {
    if (!bootstrapComplete) {
      return;
    }

    if (firstBootWorkspaceId && !urlWorkspaceId && !workspaceId) {
      navigate(`/workspaces/${firstBootWorkspaceId}`);
    } else if (
      !firstBootWorkspaceId &&
      !urlWorkspaceId &&
      workspaces?.length > 0
    ) {
      const topId = workspaces?.[0].id;
      navigate(`/workspaces/${topId}`);
    }
  }, [
    navigate,
    workspaceId,
    urlWorkspaceId,
    firstBootWorkspaceId,
    workspaces?.map((w) => w.id).join(),
    bootstrapComplete,
  ]);

  const toolbarContainer = useRef(null);

  const resizeWindow = () => {
    setToolbarSize({
      offsetWidth: toolbarContainer?.current?.offsetWidth,
      offsetLeft: toolbarContainer?.current?.offsetLeft,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  });

  UseHandsFreeStatus(toolbarContainer?.current !== null);

  useEffect(() => {
    if (toolbarContainer?.current) {
      resizeWindow();
    }
  }, [setToolbarSize, toolbarContainer.current, globalSearchOpen, leftNavOpen]);

  const [sqlEditorOpen, setSqlEditorOpen] = React.useState(false);
  const toggleSqlEditor = () => setSqlEditorOpen((value) => !value);

  if (!bootstrapComplete) {
    return <LoadingScreen />;
  }

  return (
    <>
      <SqlEditor open={sqlEditorOpen} close={() => toggleSqlEditor()} />
      <DmModal />
      <InviteToWorkspaceModal />
      <ScheduleMessageModal />
      <UserFeedbackModal />
      <Box sx={{ display: "flex" }} data-feed-layout-wrap>
        <SBAppBar data-app-bar position="fixed">
          <Toolbar
            ref={toolbarContainer}
            data-toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "center",
              width: "100%",
              px: { xs: 1, sm: 2 },
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                gap: 0.5,
              }}
            >
              <Box
                sx={{
                  mr: "auto",
                  display: "flex",
                  alignItems: "center",
                  minWidth: { sm: feedId ? 94 : 0 },
                }}
              >
                <IconButton
                  aria-label={Locator.mainNav.workspaceNavToggle}
                  onClick={() => {
                    toggleLeftNav();
                    resizeWindow();
                  }}
                  edge="start"
                  sx={{
                    background: "transparent",
                    borderRadius: "1000px",
                    ml: 0,
                  }}
                >
                  {leftNavOpen ? (
                    <Icons.ArrowBack role="img" />
                  ) : (
                    <Icons.Menu role="img" />
                  )}
                  {!leftNavOpen && unreadFeedIds?.length > 0 ? (
                    <Box
                      aria-label={
                        Locator.workspaceNav.channels.list.unreadCount
                      }
                      sx={{
                        position: "absolute",
                        bottom: 10,
                        right: 8,
                        borderRadius: "50%",
                        background: theme.palette.brand.secondary.main,
                        height: 10,
                        width: 10,
                        minWidth: 10,
                      }}
                    />
                  ) : null}
                </IconButton>
                {flags.sqlEditor && (
                  <IconButton onClick={toggleSqlEditor}>
                    <TerminalIcon role="img" />
                  </IconButton>
                )}
                {globalSearch && (
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          background: theme.palette.brand.other.gray.dark,
                        },
                      },
                    }}
                    title={
                      <Box
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        Search <strong>({searchKey}+K)</strong>
                      </Box>
                    }
                  >
                    <IconButton
                      aria-label={`Search (${searchKey}+K)`}
                      sx={{
                        background: "transparent",
                        borderRadius: "1000px",
                        ml: 1,
                      }}
                      onClick={() => setGlobalSearchOpen(true)}
                    >
                      <Icons.Search role="img" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    xs: feedId ? 200 : "max-content",
                    sm: "max-content",
                  },
                  width: "100%",
                }}
              >
                <FeedHeader />
              </Box>
              <Stack
                sx={{
                  ml: "auto",
                  flexDirection: "row",
                  width: "100%",
                  maxWidth: feedId ? 140 : 88,
                }}
              >
                {feedId ? (
                  <Stack
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <LanguageDropdown />
                    <IconButton
                      aria-label={Locator.mainNav.feedbackButton}
                      onClick={() => setUserFeedbackModalOpen(true)}
                      sx={{
                        background: "transparent",
                        borderRadius: "1000px",
                        ml: 0,
                        height: 44,
                        width: 44,
                      }}
                    >
                      <Icons.Help role="presentation" />
                    </IconButton>
                    <IconButton
                      sx={{
                        background: "transparent",
                        borderRadius: "1000px",
                        width: 44,
                      }}
                      aria-label={Locator.mainNav.membersListToggle}
                      onClick={toggleRightNav}
                      edge="start"
                    >
                      {rightNavOpen ? (
                        <Icons.ArrowForward role="img" />
                      ) : (
                        <PersonMenu role="img" />
                      )}
                    </IconButton>
                  </Stack>
                ) : null}
              </Stack>
            </Box>
          </Toolbar>
        </SBAppBar>
        <WorkspaceDrawer url={url} sx={drawerStyles} />
        <MainContent
          leftOpen={leftNavOpen}
          rightOpen={rightNavOpen}
          sx={{
            height: "100svh",
            pt: "92px",
          }}
        >
          <SearchContainer />
          {showNoValidWorkspacesModal ? (
            <NoValidWorkspacesModal open={true} />
          ) : (
            <Outlet />
          )}
        </MainContent>
        <MembersDrawer
          handleClose={toggleRightNav}
          url={url}
          sx={memberDrawerStyles}
        />
      </Box>
    </>
  );
}
