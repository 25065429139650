import { createContext, useState, useEffect, useContext } from "react";
import { SetStateType } from "@/utils";
import { useStep } from "usehooks-ts";
import { UppyContext } from "./UppyContextProvider";
import { roundToNearestMinutes } from "date-fns";

export enum UploadType {
  File = "File",
  TTS = "TTS",
}

type ScheduledUploadDetails = {
  title: string;
  description?: string;
  internalName?: string;
};

const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

type SchedulingState = {
  currentStep?: number;
  displayName?: string;
  doesRepeat?: boolean;
  unsavedChanges?: boolean;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  repeatFrequency?: number;
  repeatPeriod?: number;
  resetAll: () => void;
  resetSteps: () => void;
  scheduledText?: string;
  selectedChannels?: Array<string>;
  selectedDaysOfWeek?: Array<number>;
  selectedDate?: Date;
  scheduledUploadDetails?: ScheduledUploadDetails;
  setDisplayName: SetStateType<string>;
  setDoesRepeat?: SetStateType<boolean>;
  setRepeatFrequency: SetStateType<number>;
  setRepeatPeriod: SetStateType<number>;
  setSelectedChannels: SetStateType<Array<string>>;
  setSelectedDaysOfWeek: SetStateType<Array<number>>;
  setSelectedDate: SetStateType<Date>;
  setScheduledText: SetStateType<string>;
  setUploadDetails: SetStateType<ScheduledUploadDetails>;
  setUploadType: SetStateType<UploadType>;
  timezone?: string;
  uploadDetails?: ScheduledUploadDetails;
  uploadType?: UploadType;
};

export const SchedulingContext = createContext<SchedulingState>({
  setDisplayName: () => {},
  setRepeatFrequency: () => {},
  setRepeatPeriod: () => {},
  setSelectedChannels: () => {},
  setSelectedDaysOfWeek: () => {},
  setSelectedDate: () => {},
  setScheduledText: () => {},
  setUploadDetails: () => {},
  setUploadType: () => {},
  goToNextStep: () => {},
  goToPrevStep: () => {},
  resetAll: () => {},
  resetSteps: () => {},
  timezone,
});

const SchedulingContextProvider = ({ children }) => {
  const { addedFile, removeFiles } = useContext(UppyContext);
  const [uploadDetails, setUploadDetails] = useState<ScheduledUploadDetails>({
    title: "",
    description: "",
  });
  const [scheduledText, setScheduledText] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [uploadType, setUploadType] = useState<UploadType>(UploadType.File);
  const [selectedChannels, setSelectedChannels] = useState<Array<string>>([]);
  // default date to the nearest quarter of the hour based off Date.now()
  const [selectedDate, setSelectedDate] = useState<Date>(
    roundToNearestMinutes(new Date(Date.now()), {
      nearestTo: 15,
      roundingMethod: "ceil",
    }),
  );
  const [repeatFrequency, setRepeatFrequency] = useState<number>(1);
  const [repeatPeriod, setRepeatPeriod] = useState<number>(1);
  const [doesRepeat, setDoesRepeat] = useState<boolean>(false);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<Array<number>>(
    [],
  );

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  // max steps of 3
  const [currentStep, helpers] = useStep(3);

  const { goToNextStep, goToPrevStep, reset, setStep } = helpers;

  const resetAll = () => {
    setUploadType(UploadType.File);
    setUploadDetails({
      title: "",
      description: "",
    });
    setScheduledText("");
    setDisplayName("");
    setSelectedChannels([]);
    setSelectedDate(
      roundToNearestMinutes(new Date(Date.now()), {
        nearestTo: 15,
        roundingMethod: "ceil",
      }),
    );
    setRepeatFrequency(1);
    setRepeatPeriod(1);
    setDoesRepeat(false);
    setSelectedDaysOfWeek([]);
    removeFiles();
    resetSteps();
  };

  const resetSteps = () => {
    reset();
  };

  useEffect(() => {
    if (scheduledText || displayName || addedFile) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [addedFile, scheduledText, displayName]);

  const schedulingState: SchedulingState = {
    currentStep,
    displayName,
    doesRepeat,
    unsavedChanges,
    uploadDetails,
    uploadType,
    repeatFrequency,
    repeatPeriod,
    scheduledText,
    selectedChannels,
    selectedDaysOfWeek,
    selectedDate,
    setDisplayName,
    setDoesRepeat,
    setRepeatFrequency,
    setRepeatPeriod,
    setSelectedChannels,
    setSelectedDaysOfWeek,
    setSelectedDate,
    setScheduledText,
    setUploadDetails,
    setUploadType,
    timezone,
    goToNextStep,
    goToPrevStep,
    resetAll,
    resetSteps,
  };
  return (
    <SchedulingContext.Provider value={schedulingState}>
      {children}
    </SchedulingContext.Provider>
  );
};

export default SchedulingContextProvider;
