// import { PGlite } from "@electric-sql/pglite";
import { PGlite } from "https://cdn.jsdelivr.net/npm/@electric-sql/pglite/dist/index.js";

import { live } from "@electric-sql/pglite/live";
import { drizzle } from "drizzle-orm/pglite";
import * as schema from "./schema.js";

const client = await PGlite.create({
  // dataDir: "idb://sbv2",
  // relaxedDurability: true,
  extensions: { live },
});

const db = drizzle(client, { schema });

export { db, client };
