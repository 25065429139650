import { Ampli } from "@/ampli";
import {
  createManyWorkspace,
  createManyWorkspaceMembership,
  createManyWsAccount,
} from "@/data/pg/bulkInserts";
import {
  upsertDeviceRegistration,
  upsertDirectWsInvitation,
  upsertManyWsPermission,
  upsertMyAccount,
  upsertWorkspace,
  upsertWorkspaceMembership,
  upsertWsAccount,
  upsertWsAudioEncoding,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsCommandAlias,
  upsertWsDisplayArtifact,
  upsertWsDraft,
  upsertWsEvent,
  upsertWsFeed,
  upsertWsFeedGroup,
  upsertWsFeedGroupMembership,
  upsertWsFile,
  upsertWsHandsFreeStatus,
  upsertWsItem,
  upsertWsLink,
  upsertWsPAM,
  upsertWsPermission,
  upsertWsPublishedDraft,
  upsertWsScheduleTrigger,
  upsertWsTemplate,
  upsertWsTranscription,
} from "@/data/pg/updates";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { db } from "@/db/db";
import { account, permission, workspaceMembership } from "@/db/schema";
import { Permission, WorkspaceMembership } from "@/db/types";
import UseStorage from "@/hooks/useStorage";
import useBootstrap from "@/models/BootstrapApplication";
import { useBootTimesStore } from "@/stores/useBootTimesStore";
import { type MappedWorkspaceRole, buildVersion } from "@/utils";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import * as Sentry from "@sentry/browser";
import { useLocalStorage } from "@uidotdev/usehooks";
import cuid from "cuid";
import { and, eq } from "drizzle-orm";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AppContext as ApplicationContext,
  DeviceContext,
  DeviceRegistration,
  UserInfoResponse,
  WsAppSyncEvent,
  WsEvent,
  WsItem,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { AppContext } from "./AppStateProvider";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { TrackingContext } from "./TrackingStateProvider";
import { UnreadsContext } from "./UnreadsContextProvider";
import { downloadPaginatedBootstrapFeedItems } from "./actions/initialFeedLoad";
import { LANGUAGE_LIST, PreferredLanguage } from "./languages";
import { connectControlsToElement } from "@/components/AudioPlayer/timeline";
import { logger } from "@/utils/logging";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

let userValidated = false;

const ITEMS_PER_PAGE = 10;
let subscription: any;

const LANGUAGE_SESSION_KEY = "preferredLanguage";
const LANGUAGE_DEFAULT_VALUE = LANGUAGE_LIST[0] ?? "none";

function appSyncSubscriptionUpdate(
  event: WsAppSyncEvent,
  handleUnreadItem: (items: WsItem, myAccountId: string) => Promise<void>,
  handleReadItemEvent: (events: WsEvent, myAccountId: string) => Promise<void>,
  client: Client,
  myAccountId: string,
) {
  console.log("app sync", event);
  for (const i of event?.accounts || []) {
    upsertWsAccount(i);
  }
  for (const i of event?.workspaces || []) {
    upsertWorkspace(i);
  }
  for (const i of event?.workspaceMemberships || []) {
    upsertWorkspaceMembership(i);
  }
  for (const i of event?.directWorkspaceInvitations || []) {
    upsertDirectWsInvitation(i);
  }
  for (const i of event?.feeds || []) {
    upsertWsFeed(i);
  }
  for (const i of event?.feedGroups || []) {
    upsertWsFeedGroup(i);
  }
  for (const i of event?.feedGroupMemberships || []) {
    upsertWsFeedGroupMembership(i);
  }
  for (const i of event?.permissions || []) {
    upsertWsPermission(i);
  }
  for (const i of event?.items || []) {
    upsertWsItem(i);
  }
  for (const i of event?.audioEncodings || []) {
    upsertWsAudioEncoding(i);
  }
  for (const i of event?.displayArtifacts || []) {
    upsertWsDisplayArtifact(i);
  }
  for (const i of event?.files || []) {
    upsertWsFile(i);
  }
  for (const i of event?.links || []) {
    upsertWsLink(i);
  }
  for (const i of event?.transcriptions || []) {
    upsertWsTranscription(i);
  }
  for (const i of event?.events || []) {
    upsertWsEvent(i);
  }
  for (const i of event?.commandAliases || []) {
    upsertWsCommandAlias(i);
  }

  for (const i of event?.workflowItems || []) {
    upsertWsDraft(i);
  }

  for (const i of event?.scheduleTriggers || []) {
    upsertWsScheduleTrigger(i);
  }

  for (const i of event?.broadcastActions || []) {
    upsertWsBroadcastAction(i);
  }

  for (const i of event?.publishedWorkflowItems || []) {
    upsertWsPublishedDraft(i);
  }

  for (const i of event?.broadcastRecipients || []) {
    upsertWsBroadcastRecipient(i);
  }
  for (const i of event?.pam || []) {
    upsertWsPAM(i);
  }
  for (const i of event?.templates || []) {
    upsertWsTemplate(i);
  }
  for (const i of event?.deviceRegistrations || []) {
    upsertDeviceRegistration(i);
  }

  if (event?.items?.length > 0) {
    for (const i of event.items) {
      handleUnreadItem(i, myAccountId);
    }
  }
  if (event?.events?.length > 0) {
    for (const e of event.events) {
      handleReadItemEvent(e, myAccountId);
    }
  }
}

type DataState = {
  appContext?: ApplicationContext;
  availableWorkspaceRoles?: Map<string, MappedWorkspaceRole>;
  getWorkspaceRoleLabel?: (role: string) => string;
  deviceContext?: DeviceContext;
  bootstrapComplete?: boolean;
  firstBootWorkspaceId?: string;
  loadWorkspaceWorkflowItems?: (params: {
    workspaceId: string;
    myAccountId: string;
  }) => Promise<void>;
  preferredLanguage: PreferredLanguage;
  setPreferredLanguage?: (language: PreferredLanguage) => void;
  fetchWorkspaceMembership?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<WorkspaceMembership>;
  listFeedPermissions?: (
    workspaceMembershipId: string,
    feedId: string,
  ) => Promise<Array<Permission>>;
  joinPublicChannel?: (workspaceId: string, feedId: string) => Promise<boolean>;
  leavePublicChannel?: (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ) => Promise<boolean>;
  joinFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  leaveFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
  }) => Promise<boolean>;
  fetchWorkspaceHandsFreeStatus?: () => Promise<void>;
  fetchGeoCoords?: (
    workspaceId: string,
    membershipIds: string[],
  ) => Promise<{ lat: number; lng: number } | null>;
};

//create a context, with createContext api
export const DataContext = createContext<DataState>({
  preferredLanguage: "en",
});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  client: Client;
};

const DataProvider = ({ children, client }: Props) => {
  const { pubSub } = React.useContext(AppContext);
  const { handleUnreadItem, handleReadItemEvent } =
    React.useContext(UnreadsContext);
  const { limitedMemberRole, trackBootTimes, amplitudeSessionTracking } =
    useFlags();
  const ldClient = useLDClient();
  const bootTimesStore = useBootTimesStore();
  const { ampli }: { ampli: Ampli } = React.useContext(TrackingContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const [bootstrapComplete, setBootstrapComplete] = useState<boolean>(false);
  const [firstBootWorkspaceId, setFirstBootWorkspaceId] = useState<
    string | null
  >(null);
  const [user, setUser] = useState<UserInfoResponse | null>(null);

  const { getLocalStorage, setLocalStorage } = UseStorage();

  const { myAccount } = React.useContext(MyAccountContext);
  const { currentWorkspaceId, workspaces } = React.useContext(WorkspaceContext);
  const [deviceRegistrationId] = useLocalStorage("deviceRegistrationId", "");

  const localStoragePreferredLanguage = getLocalStorage(
    "preferredLanguage",
  ) as PreferredLanguage;

  const preferredLanguage =
    localStoragePreferredLanguage || LANGUAGE_DEFAULT_VALUE;

  useEffect(() => {
    bootTimesStore.injectAmplitude(ampli);
    bootTimesStore.setLogBootTimes(trackBootTimes);
    bootTimesStore.addBootTime({ event: "BootApplication", name: "start" });
    bootTimesStore.addBootTime({ event: "TTSFooter", name: "start" });
    bootTimesStore.addBootTime({
      event: "WorkspaceFeedChannelList",
      name: "start",
    });
  }, []);

  const setPreferredLanguage = useCallback(
    async (language: PreferredLanguage) => {
      if (!myAccount?.id) return;
      console.log("setPreferredLanguage", language);
      await db
        .update(account)
        .set({ preferredLanguage: language })
        .where(eq(account.id, myAccount.id))
        .execute()
        .then(() => {
          setLocalStorage({ key: LANGUAGE_SESSION_KEY, value: language });
        });
    },
    [myAccount?.id, setLocalStorage],
  );

  const applicationContext = useMemo(() => {
    return {
      handsFreeEnabled: handsFreeEnabledForWorkspace(currentWorkspaceId),
      selectedWorkspaceId: currentWorkspaceId,
      workspaces:
        workspaces?.map((w) => {
          return {
            handsFreeEnabled: handsFreeEnabledForWorkspace(w?.id) || false,
            workspaceId: w?.id || "",
            feedSelected:
              w?.id === currentWorkspaceId ? currentFeedId : undefined,
            languageSelected: preferredLanguage,
          };
        }) || [],
    } as ApplicationContext;
  }, [currentWorkspaceId, workspaces, currentFeedId, preferredLanguage]);

  const deviceContext = useMemo(() => {
    return {
      surface: "web",
      surfaceContext: window?.navigator?.userAgent,
      surfaceBuild: buildVersion(),
      notificationsEnabled: Notification?.permission === "granted",
      internetConnectionStatus: "online",
      deviceLanguage: window?.navigator?.language || preferredLanguage,
      deviceRegistrationId: deviceRegistrationId,
    } as DeviceContext;
  }, [deviceRegistrationId, preferredLanguage]);

  // setup mapped workspace roles from api -> client label
  const availableWorkspaceRoles = new Map<string, MappedWorkspaceRole>([
    ["admin", { role: "admin", label: "Administrator", enabled: true }],
    ["member", { role: "member", label: "Organizer", enabled: true }],
    [
      "limitedMember",
      {
        role: "limitedMember",
        label: "Member",
        enabled: limitedMemberRole,
      },
    ],
  ]);

  const getWorkspaceRoleLabel = (role: string) => {
    return availableWorkspaceRoles?.get(role)?.label || role;
  };

  const subscribeToAppSync = React.useCallback(
    (accountIds: string[], workspaceIds: string[]) => {
      const accountId = accountIds[0];
      if (!pubSub || !client) return;
      if (!accountId) return;

      const channels: string[] = [];
      for (const accountId of accountIds) {
        channels.push(`wsaccount#${accountId}`);
      }
      if (workspaceIds?.length > 0) {
        for (const wid of workspaceIds) {
          channels.push(`workspace#${wid}`);
        }
      }
      const newSub = pubSub.subscribeFilter(
        channels,
        (data?: any) => {
          if (data?.data) {
            const eventData = JSON.parse(data.data) as WsAppSyncEvent;
            appSyncSubscriptionUpdate(
              eventData,
              handleUnreadItem,
              handleReadItemEvent,
              client,
              accountId,
            );
          } else {
            console.warn(
              "Account Feed Subscription Event called with no data",
              data,
            );
          }
        },
        (e: any) => {
          console.error("Account Feed Subscription Error", e);
        },
      );
      subscription?.unsubscribe();
      subscription = newSub;
    },
    [pubSub, handleUnreadItem, handleReadItemEvent, client],
  );

  useEffect(() => {
    const validateUser = async () => {
      try {
        bootTimesStore.addBootTime({ event: "ValidateUser", name: "start" });
        const userInfoResponse = await client.getUserInfo();

        if (userInfoResponse?.workspaces?.length > 0) {
          await createManyWorkspace(userInfoResponse?.workspaces || []);
        }
        if (userInfoResponse?.workspaceMemberships?.length > 0) {
          await createManyWorkspaceMembership(
            userInfoResponse?.workspaceMemberships || [],
          );
        }
        if (userInfoResponse?.accounts?.length > 0) {
          await createManyWsAccount(userInfoResponse?.accounts || []);
        }
        setUser((prev) =>
          JSON.stringify(prev) !== JSON.stringify(userInfoResponse)
            ? userInfoResponse
            : prev,
        );
        bootTimesStore.addBootTime({ event: "ValidateUser", name: "finish" });
        const sessionStorageValue =
          window?.sessionStorage?.getItem(LANGUAGE_SESSION_KEY);
        if (sessionStorageValue) {
          logger(["FOUND SESSION STORAGE VALUE", sessionStorageValue]);
        } else {
          logger("NO SESSION STORAGE VALUE");
        }
        await upsertMyAccount(userInfoResponse);
        const accountRecord = await db.query.account
          .findFirst({
            where: eq(account.mine, true),
          })
          .execute();
        if (accountRecord) {
          if (!account?.preferredLanguage) {
            await db
              .update(account)
              .set({ preferredLanguage: sessionStorageValue || "none" })
              .where(eq(account.id, accountRecord.id))
              .execute();
          }
          ampli.client.setUserId(accountRecord.id);

          Sentry.setUser({ accountId: account.id });
          ampli.authSuccess({ "Auth0 App": "SMS" });
          ldClient
            .identify({
              kind: "user",
              key: accountRecord.id,
              name: accountRecord?.name,
              email: accountRecord?.email,
              sbAccount: accountRecord?.email?.includes("@storyboard.fm"),
            })
            .catch((e) => {
              console.error(e);
            });
          if (window.dataLayer) {
            window.dataLayer.push({
              event: {
                name: "authSuccess",
                value: "Auth0 App: SMS",
              },
            });
          }
        }
      } catch (e) {
        const maintenancePage =
          window.location.pathname.includes("maintenance-mode");
        if (e?.status === 418 && !maintenancePage) {
          window.location.href = "/maintenance-mode";
        }
        return Promise.reject(e);
      }
    };
    if (!userValidated) {
      validateUser();
      userValidated = true;
    }
  }, [subscribeToAppSync]);

  useEffect(() => {
    if (myAccount?.id && currentWorkspaceId) {
      subscribeToAppSync(
        [myAccount?.id],
        currentWorkspaceId ? [currentWorkspaceId] : [],
      );
    }
  }, [currentWorkspaceId, myAccount]);

  useEffect(() => {
    if (amplitudeSessionTracking) {
      if (amplitudeSessionTracking) {
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 0.3,
        });
        amplitude.add(sessionReplayTracking);
      }
    }
  }, [amplitudeSessionTracking]);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    null,
  );
  const { allComplete, activeWorkspaceId, bootstrapPaginatedApplication } =
    useBootstrap();

  let localWorkspaceId = null;
  useEffect(() => {
    bootTimesStore.addBootTime({
      event: "BootApplication",
      name: "start-boot",
    });
    if (
      selectedWorkspaceId &&
      currentWorkspaceId &&
      selectedWorkspaceId !== currentWorkspaceId
    ) {
      localWorkspaceId = currentWorkspaceId;
    }

    if (
      !currentWorkspaceId &&
      !selectedWorkspaceId &&
      user?.workspaces?.length > 0
    ) {
      localWorkspaceId =
        user.workspaces.find((w) => w.name.match(/dev/i))?.id ||
        user.workspaces[0].id;
    }

    if (currentWorkspaceId && !selectedWorkspaceId) {
      localWorkspaceId = currentWorkspaceId;
    }
    // resubscribe to app sync
    subscribeToAppSync(
      [user?.session?.credentials?.[0].CredentialScopes?.[0].accountId],
      localWorkspaceId ? [localWorkspaceId] : [currentWorkspaceId || ""],
    );

    if (localWorkspaceId) {
      bootstrapPaginatedApplication({
        workspaceId: localWorkspaceId,
        forceBootstrap: currentWorkspaceId !== localWorkspaceId,
      }).then(() => {
        setSelectedWorkspaceId(() => localWorkspaceId);
        localWorkspaceId = null;
        bootTimesStore.addBootTime({
          event: "BootApplication",
          name: "complete-async-boot",
        });
      });
    } else if (user?.session?.credentials?.[0]?.id && !localWorkspaceId) {
      console.error("No workspace to bootstrap");
    }
  }, [
    user?.session?.credentials?.[0]?.id,
    localWorkspaceId,
    currentWorkspaceId,
    selectedWorkspaceId,
  ]);

  useEffect(() => {
    if (allComplete) {
      setFirstBootWorkspaceId(() => activeWorkspaceId);
      setBootstrapComplete(() => allComplete);
      bootTimesStore.addBootTime({
        event: "BootApplication",
        name: "all-complete",
      });
    }
  }, [allComplete, activeWorkspaceId]);

  const loadWorkspaceWorkflowItems = async ({
    workspaceId,
    myAccountId,
  }: { workspaceId: string; myAccountId: string }) => {
    if (!myAccountId || !workspaceId) return;
    await client.getWorkspaceWorkflowItems(workspaceId).then((resp) => {
      console.log("Workspace workflow items response", { resp });
      for (const workflowItem of resp?.workflowItems || []) {
        upsertWsDraft(workflowItem);
      }
      // load associated content of the workflow items
      for (const i of resp?.audioEncodings || []) {
        upsertWsAudioEncoding(i);
      }
      for (const i of resp?.files || []) {
        upsertWsFile(i);
      }
      for (const i of resp?.links || []) {
        upsertWsLink(i);
      }
      for (const i of resp?.transcriptions || []) {
        upsertWsTranscription(i);
      }
      for (const i of resp?.displayArtifacts || []) {
        upsertWsDisplayArtifact(i);
      }
    });

    await client.getScheduledBroadcasts(workspaceId).then((resp) => {
      logger(["Workspace scheduled broadcasts response", { resp }]);
      for (const scheduleTrigger of resp?.scheduleTriggers || []) {
        upsertWsScheduleTrigger(scheduleTrigger);
      }
      for (const broadcastAction of resp?.broadcastActions || []) {
        upsertWsBroadcastAction(broadcastAction);
      }
      for (const broadcastRecipient of resp?.broadcastRecipients || []) {
        upsertWsBroadcastRecipient(broadcastRecipient);
      }
    });
  };

  const fetchWorkspaceMembership = async (
    accountId: string,
    workspaceId: string,
  ) => {
    logger(["fetchWorkspaceMembership", accountId, workspaceId]);
    const workspaceMembershipRecord = await db.query.workspaceMembership
      .findFirst({
        where: and(
          eq(workspaceMembership.accountId, accountId),
          eq(workspaceMembership.workspaceId, workspaceId),
          eq(workspaceMembership.status, "active"),
        ),
      })
      .execute();
    if (!workspaceMembershipRecord) {
      return Promise.reject("Invalid Workspace");
    }
    return workspaceMembershipRecord;
  };

  const listFeedPermissions = React.useCallback(
    async (
      workspaceMembershipId: string,
      feedId: string,
    ): Promise<Array<Permission>> => {
      logger(["listFeedPermissions", workspaceMembershipId, feedId]);
      return await db.query.permission
        .findMany({
          where: and(
            eq(permission.feedId, feedId),
            eq(permission.workspaceMembershipId, workspaceMembershipId),
          ),
        })
        .execute();
    },
    [],
  );

  const joinPublicChannel = async (
    workspaceId: string,
    feedId: string,
  ): Promise<boolean> => {
    const permissions = await client.joinPublicFeed(workspaceId, feedId);
    for (const p of permissions) {
      upsertWsPermission(p);
    }
    // after joining the feed, download the feed items
    await downloadPaginatedBootstrapFeedItems(client, workspaceId);
    return true;
  };

  const leavePublicChannel = async (
    workspaceId: string,
    feedId: string,
    membershipId: string,
  ): Promise<boolean> => {
    await client.unSubscribeFromWorkspaceFeed(workspaceId, feedId);
    await db
      .delete(permission)
      .where(
        and(
          eq(permission.feedId, feedId),
          eq(permission.workspaceMembershipId, membershipId),
        ),
      )
      .execute();
    return true;
  };

  const leaveFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.leaveFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(permissions);
    return true;
  };

  const joinFeedGroup = async ({
    workspaceId,
    feedGroupId,
  }: { workspaceId: string; feedGroupId: string }): Promise<boolean> => {
    const response = await client.joinFeedGroup({ workspaceId, feedGroupId });
    const permissions = response?.permissions || [];
    await upsertManyWsPermission(permissions);

    await downloadPaginatedBootstrapFeedItems(client, workspaceId);
    return true;
  };

  const fetchWorkspaceHandsFreeStatus = async (): Promise<void> => {
    if (!currentWorkspaceId) return;
    logger("fetchWorkspaceHandsFreeStatus");
    const allWorkspaceMemberships = await db.query.workspaceMembership
      .findMany({
        where: and(
          eq(workspaceMembership.status, "active"),
          eq(workspaceMembership.workspaceId, currentWorkspaceId),
        ),
      })
      .execute();

    const handsFreeStatusResponse = await client.getHandsFreeStatus(
      currentWorkspaceId,
      {
        membershipIds: allWorkspaceMemberships.map((m) => m.id),
      },
    );

    if (!handsFreeStatusResponse?.data) return;

    const { data } = handsFreeStatusResponse;

    const checkTimestampAgainstDate = (timestamp: string): boolean => {
      const now = new Date();
      const nowGmt = now.toUTCString();
      const gmt = new Date(timestamp).toUTCString();
      const nowTime = new Date(nowGmt).getTime();
      const timestampTime = new Date(gmt).getTime();
      const diff = nowTime - timestampTime;
      const millisecondsInMinute: number = 60000;
      const minutes: number = 11;
      const threshold: number = minutes * millisecondsInMinute;
      return diff >= threshold;
    };

    const mappedResponse: Array<{
      id: string;
      enabled: boolean;
      timestamp: string;
    }> = data
      ?.filter((item) => item?.statuses[0]?.timestamp)
      ?.map((item) => {
        const staleTimestamp = checkTimestampAgainstDate(
          item.statuses[0].timestamp,
        );
        const enabled = item.statuses[0].enabled;
        return {
          id: item.membershipId,
          enabled: staleTimestamp ? false : enabled,
          timestamp: item.statuses[0].timestamp,
        };
      });

    if (mappedResponse?.length > 0) {
      for (const status of mappedResponse) {
        upsertWsHandsFreeStatus(status);
      }
    }
  };

  const fetchGeoCoords = async (
    workspaceId: string,
    membershipIds: string[],
  ): Promise<{ lat: number; lng: number } | null> => {
    const { data } = await client.getLocations(workspaceId, { membershipIds });
    return data[0]?.locations[0]?.latitude
      ? {
          lat: data[0]?.locations[0]?.latitude,
          lng: data[0]?.locations[0]?.longitude,
        }
      : null;
  };

  useEffect(() => {
    const f = async () => {
      try {
        let deviceId = deviceRegistrationId;
        if (!deviceId) {
          deviceId = cuid();
        }
        const newDeviceReg = {
          id: deviceId,
          surface: "web",
        } as DeviceRegistration;
        const deviceRegistration = await client.registerDevice({
          deviceRegistration: newDeviceReg,
        });
        if (deviceRegistration?.id) {
          // save to local storage
          await upsertDeviceRegistration(deviceRegistration);
        }
      } catch (error) {
        logger(["Error registering device", error], true);
      }
    };
    f();
  }, []);

  const dataState: DataState = {
    appContext: applicationContext,
    availableWorkspaceRoles,
    getWorkspaceRoleLabel,
    joinPublicChannel,
    leavePublicChannel,
    joinFeedGroup,
    leaveFeedGroup,
    deviceContext,
    bootstrapComplete,
    firstBootWorkspaceId,
    preferredLanguage,
    setPreferredLanguage: setPreferredLanguage,
    fetchWorkspaceMembership,
    listFeedPermissions,
    fetchWorkspaceHandsFreeStatus,
    fetchGeoCoords,
    loadWorkspaceWorkflowItems,
  };
  return (
    <DataContext.Provider value={dataState}>{children}</DataContext.Provider>
  );
};
export default DataProvider;
