import { FlagData } from "@/routes";
import React, { createContext, useState } from "react";
import PubSub from "@/PubSub";
import Client from "web-client/client";
import { Typography } from "@mui/material";
import BrowserErrorRefresh from "@/components/ModalForms/BrowserErrorRefresh";
import { useFlags } from "launchdarkly-react-client-sdk";

export type Config = {
  baseDomain: string;
  graphqlApiKey: string;
  graphqlUrl: string;
  sessionRecordingLength: number;
  tusUrl: string;
};

type AppState = {
  audioCtx?: AudioContext;
  client: Client;
  config?: Config;
  flags: FlagData;
  pubSub?: PubSub;
  redirectPath?: string;
  setFlags: (flags: FlagData) => void;
  setRedirectPath: (path: string) => void;
  tracking?: any;
};

const defaultClient = new Client({});

//create a context, with createContext api
export const AppContext = createContext<AppState>({
  client: defaultClient,
  setRedirectPath: () => {},
  flags: {},
  setFlags: (_) => {},
});

type Props = {
  client: Client;
  baseDomain?: string;
  children: React.ReactNode | React.ReactNode[];
  graphqlApiKey: string;
  graphqlUrl: string;
  sessionRecordingLength: number;
  tusUrl: string;
  initialFlags: FlagData;
};

const AppStateProvider = ({
  client,
  baseDomain,
  tusUrl,
  graphqlUrl,
  graphqlApiKey,
  children,
  sessionRecordingLength,
  initialFlags,
}: Props) => {
  const [flags, setFlags] = useState<FlagData>(initialFlags);
  const [redirectPath, setRedirectPath] = useState<string>("");
  const [errorCodeDialog, setErrorCodeDialog] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState<number>(0);
  const { showRefreshPrompt } = useFlags();

  const clearErrors = () => {
    setErrorCodeDialog(() => false);
    setErrorCount(() => 0);
  };

  const handleErrorCodes = (error: any) => {
    setErrorCount((value) => value + 1);
    const findString = "net::ERR";
    if (
      error?.message?.includes(findString) ||
      error?.stack?.includes(findString)
    ) {
      setErrorCodeDialog(() => true);
    } else if (errorCount >= 10 && !errorCodeDialog) {
      setErrorCodeDialog(() => true);
    }
  };
  client.on("success", () => clearErrors());
  client.on("errorCodes", (errors) => handleErrorCodes(errors));

  const pubSub = React.useMemo(
    () => new PubSub(graphqlUrl, graphqlApiKey),
    [graphqlUrl, graphqlApiKey],
  );

  const appState: AppState = {
    client,
    redirectPath,
    setRedirectPath,
    flags,
    setFlags,
    pubSub,
    config: {
      baseDomain,
      graphqlApiKey,
      graphqlUrl,
      sessionRecordingLength,
      tusUrl,
    },
  };

  return (
    <>
      {showRefreshPrompt ? (
        <BrowserErrorRefresh
          open={errorCodeDialog}
          setOpen={() => {
            setErrorCount(() => 0);
            setErrorCodeDialog(() => false);
          }}
        >
          <Typography sx={{ mb: 1 }}>
            Your browser is having trouble connecting to the internet.
          </Typography>
          <Typography>Please reload the page to continue.</Typography>
        </BrowserErrorRefresh>
      ) : null}
      <AppContext.Provider value={appState}>{children}</AppContext.Provider>
    </>
  );
};
export default AppStateProvider;
