import { db } from "@/db/db";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { feed, feedGroupMembership, item, permission } from "@/db/schema";
import { Feed, FeedGroup } from "@/db/types";
import { and, desc, eq, ne, or, sql } from "drizzle-orm";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import {
  groupedFeedsQuery,
  groupsQuery,
  myActiveFeedsQuery,
} from "./commonQueries";

interface FeedWithGroup extends Feed {
  groupId?: string;
  latestActivity: string;
  feedId?: string;
  name?: string;
}
interface GroupWithFeeds extends FeedGroup {
  feeds: FeedWithGroup[];
}

type LiveQueries = {
  fetchNextActiveChannel?: (groupId?: string) => Promise<string>;
  myActiveFeeds?: Awaited<ReturnType<typeof myActiveFeedsQuery>>;
  groups?: Array<FeedGroup>;
  groupsOfFeeds?: Array<GroupWithFeeds>;
  nonGroupedFeeds?: Array<FeedWithGroup>;
  workspaceMembershipId?: string | null;
};

export const LiveQueryContext = createContext<LiveQueries>({});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const LiveQueriesProvider = ({ children }: Props) => {
  const { currentWorkspaceId: workspaceId, myCurrentWorkspaceMembership } =
    useContext(WorkspaceContext);
  const { currentFeedId: feedId } = useContext(CurrentFeedContext);

  // List of active feeds that the current user has permissions to
  const { rows: myActiveFeeds } = useDrizzleSelect(
    myActiveFeedsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  // Fetch the next active channel available to the user
  const fetchNextActiveChannel = async (groupId?: string): Promise<string> => {
    const realOrFakeFeedId = !feedId ? "NULL" : feedId;
    const realOrFakeGroupId = !groupId ? "NULL" : groupId;

    console.log("Fetching next active channel");

    const activeChannel = await db
      .select({
        id: feed.id,
        feedId: permission.feedId,
        workspaceMembershipId: permission.workspaceMembershipId,
        enabled: permission.enabled,
        title: feed.title,
        groupId: feedGroupMembership.groupId,
        latestActivity: sql`GREATEST(
        coalesce(${feed.updatedAt}, DATE '0001-01-01'),
        coalesce(${item.createdAt}, DATE '0001-01-01')
      ) as latestActivity`,
      })
      .from(permission)
      .innerJoin(feed, eq(feed.id, permission.feedId))
      .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
      .leftJoin(item, eq(feed.id, item.feedId))
      .where(
        and(
          eq(
            permission.workspaceMembershipId,
            myCurrentWorkspaceMembership?.id,
          ),
          eq(permission.enabled, true),
          eq(feed.workspaceId, workspaceId),
          ne(feed.id, realOrFakeFeedId),
          or(
            ne(feedGroupMembership.groupId, realOrFakeGroupId),
            eq(feedGroupMembership.groupId, null),
          ),
        ),
      )
      .groupBy(feed.id)
      .orderBy(desc(sql`latestActivity`))
      .limit(1)
      .execute();

    // const activeChannel = await db.raw({
    //   sql: `
    //     SELECT
    //         permission.feedId as feedId,
    //         permission.workspace_membershipId as workspaceMembershipId,
    //         permission.enabled as enabled,
    //         feed.title,
    //         feed.id,
    //         feed_group_membership.groupId as groupId,
    //         (GREATEST(
    //             coalesce(feed.updatedAt, DATE '0001-01-01'),
    //             coalesce(feed.latestActivity, DATE '0001-01-01'),
    //             coalesce(item.createdAt, DATE '0001-01-01')
    //          )) as latestActivity
    //     FROM
    //         permission
    //         JOIN feed ON feed.id = permission.feedId
    //         LEFT OUTER JOIN feed_group_membership on feed.id = feed_group_membership.feedId
    //         LEFT OUTER JOIN item ON feed.id = item.feedId
    //     WHERE permission.workspace_membershipId = ?
    //     AND permission.enabled = 1
    //     AND feed.workspaceId = ?
    //     AND feed.id != ?
    //     AND (feed_group_membership.groupId != ? OR feed_group_membership.groupId IS NULL)
    //     GROUP BY feed.id
    //     ORDER BY latestActivity DESC
    //     LIMIT 1
    //   `,
    //   args: [
    //     myCurrentWorkspaceMembership?.id,
    //     workspaceId,
    //     realOrFakeFeedId,
    //     realOrFakeGroupId,
    //   ],
    // });

    if (activeChannel?.length === 0) {
      return `/workspaces/${workspaceId}`;
    }
    return `/workspaces/${workspaceId}/feeds/${activeChannel[0]?.id}`;
  };

  // const { rows: groups } = useDrizzleSelect(groupsQuery({ workspaceId }));
  // const { rows: groupedFeeds } = useDrizzleSelect(
  //   groupedFeedsQuery({
  //     workspaceId,
  //     myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
  //   }),
  // );

  // const groupsOfFeeds = useMemo(
  //   () =>
  //     groups
  //       ?.map((group) => ({
  //         ...group,
  //         feeds: groupedFeeds?.filter((feed) => feed?.groupId === group.id),
  //       }))
  //       ?.filter((group) => group?.feeds?.length > 0),
  //   [groups, groupedFeeds],
  // );

  // const nonGroupedFeeds = useMemo(
  //   () => myActiveFeeds?.filter((feed) => !feed?.groupId),
  //   [myActiveFeeds],
  // );

  /// →  →  →  →  →  →  →  →  →  →  →  →  →  →  →  →  Return All Live Queries
  const LiveQueryState: LiveQueries = {
    fetchNextActiveChannel,
    myActiveFeeds,
    // groups,
    // groupsOfFeeds,
    // nonGroupedFeeds,
    workspaceMembershipId: myCurrentWorkspaceMembership?.id,
  };

  return (
    <LiveQueryContext.Provider value={LiveQueryState}>
      {children}
    </LiveQueryContext.Provider>
  );
};

export default LiveQueriesProvider;
