import { buildVersion } from "@/utils";
import cuid from "cuid";
import { AccountEvent as AccountEventClient } from "./api/AccountEvent";
import { AccountEvents as AccountEventsClient } from "./api/AccountEvents";
import { Accounts as AccountsClient } from "./api/Accounts";
import { Activity as ActivityClient } from "./api/Activity";
import { Bootstrap as BootstrapClient } from "./api/Bootstrap";
import { Content as ContentClient } from "./api/Content";
import { Device as DeviceClient } from "./api/Device";
import { Echo as EchoClient } from "./api/Echo";
import { Login } from "./api/Login";
import { Logs as LogsClient } from "./api/Logs";
import { Userinfo as UserInfoClient } from "./api/Userinfo";
import { WorkspaceId as SearchClient } from "./api/WorkspaceId";
import { Workspaces as WorkspacesClient } from "./api/Workspaces";
import {
  AccountEventRequest,
  AccountEventRequests,
  ContentEventStep,
  ContentEventStepStatus,
  ContentLanguageRequest,
  ContentPushNotificationType,
  ContentPushSourceEventType,
  ContentReceiptType,
  CreateContentEventRequests,
  CreateContentRecieptRequests,
  DeviceContext,
  DeviceRegistration,
  DrivingStatus,
  HandsFreeStatusRequest,
  HeartbeatRequest,
  LocationsRequest,
  SearchFeedsRequest,
  SearchFeedsResponse,
  SearchMessageResponse,
  SearchMessagesRequest,
  UploadDeviceLogs,
  WorkspaceInvitation,
  WorkspaceRole,
} from "./api/data-contracts";
import { HttpResponse, RequestParams } from "./api/http-client";

export default class Client {
  public apiBaseUrl: string;
  public authBaseUrl: string;

  public userInfoClient: UserInfoClient;
  public contentClient: ContentClient;
  public accountEventClient: AccountEventClient;
  public accountEventsClient: AccountEventsClient;
  public loginClient: Login;
  public accountClients: AccountsClient;
  public workspacesClient: WorkspacesClient;
  public searchClient: SearchClient;
  public bootstrapClient: BootstrapClient;
  public logsClient: LogsClient;
  public echoClient: EchoClient;
  public activityClient: ActivityClient;
  public deviceClient: DeviceClient;

  public baseDomain: string;
  public login?: () => void;
  public logout?: (returnTo?: string) => void;
  public headers?: any;
  public eventMap: any;

  constructor({
    baseDomain,
    headers,
    login,
    logout,
  }: {
    baseDomain?: string;
    headers?: any;
    login?: () => void;
    logout?: (returnTo?: string) => void;
  }) {
    this.eventMap = new Map();
    this.headers = headers;
    this.baseDomain = baseDomain || "https://dev.app.storyboardtesting.com";

    this.login = login;
    this.logout = logout;

    this.apiBaseUrl = `${this.baseDomain}/v1`;
    this.authBaseUrl = `${this.baseDomain}/auth`;

    this.userInfoClient = new UserInfoClient({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.contentClient = new ContentClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountEventClient = new AccountEventClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountEventsClient = new AccountEventsClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.loginClient = new Login({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.accountClients = new AccountsClient({
      baseUrl: this.authBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.workspacesClient = new WorkspacesClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.bootstrapClient = new BootstrapClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.logsClient = new LogsClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.echoClient = new EchoClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.searchClient = new SearchClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.activityClient = new ActivityClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
    this.deviceClient = new DeviceClient({
      baseUrl: this.apiBaseUrl,
      baseApiParams: { credentials: "include", headers },
    });
  }

  on(event: string, callback: (value: any) => void) {
    if (!this.eventMap.has(event)) {
      this.eventMap.set(event, []);
    }
    this.eventMap.get(event).push(callback);
  }

  remove(event: string, callback: (value: any) => void) {
    if (this.eventMap.has(event)) {
      const callbacks = this.eventMap
        .get(event)
        .filter((cb) => cb !== callback);
      this.eventMap.set(event, callbacks);
    }
  }
  emit(event: string, ...data: any) {
    if (this.eventMap.has(event)) {
      this.eventMap.get(event).forEach((callback) => callback(...data));
    }
  }

  public async createScheduledBroadcast({
    workspaceId,
    feedIds,
    workspaceMembershipIds,
    workflowItemId,
    date,
    cron,
    timezone,
  }: {
    workspaceId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
    workflowItemId: string;
    date?: string;
    cron?: string;
    timezone?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.triggerScheduleCreate(workspaceId, {
        feedIds,
        workspaceMembershipIds,
        workflowItemId,
        workspaceId,
        date,
        cron,
        timezone,
      }),
    );
  }

  public async updateScheduledBroadcast({
    workspaceId,
    scheduleId,
    feedIds,
    workspaceMembershipIds,
    date,
    cron,
    timezone,
  }: {
    workspaceId: string;
    scheduleId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
    date?: string;
    cron?: string;
    timezone?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.triggerScheduleUpdate(workspaceId, scheduleId, {
        feedIds,
        workspaceMembershipIds,
        date,
        cron,
        timezone,
      }),
    );
  }

  public async getScheduledBroadcasts(workspaceId: string) {
    return this.processRequest(
      this.workspacesClient.scheduledBroadcastsDetail(workspaceId),
    );
  }

  public async publishBroadcast({
    workspaceId,
    contentId,
    workspaceMembershipIds,
    feedIds,
    inputText,
  }: {
    workspaceId: string;
    contentId: string;
    workspaceMembershipIds?: string[];
    feedIds?: string[];
    inputText?: string;
  }) {
    return this.processRequest(
      this.workspacesClient.publishBroadcastCreate(workspaceId, {
        contentId,
        workspaceMembershipIds,
        feedIds,
        workspaceId,
        inputText,
      }),
    );
  }

  public async createWorkspaceWorkflowItem({
    workspaceId,
    contentId,
    displayName,
    workflowItemId,
    inputText,
  }: {
    workspaceId: string;
    contentId?: string;
    displayName?: string;
    workflowItemId?: string;
    inputText?: string;
  }) {
    contentId = contentId || cuid();
    workflowItemId = workflowItemId || cuid();
    return this.processRequest(
      this.workspacesClient.workflowItemsCreate(workspaceId, {
        workspaceId,
        contentId,
        displayName,
        workflowItemId,
        inputText,
      }),
    );
  }

  public async joinPublicFeed(workspaceId: string, feedId: string) {
    await this.processRequest(
      this.workspacesClient.feedsJoinUpdate(workspaceId, feedId),
    );

    const { permissions } = await this.processRequest(
      this.workspacesClient.feedsPermissionsDetail(workspaceId, feedId, {
        page: 0,
        pageSize: 1000,
      }),
    );

    return permissions;
  }

  public async getWorkspaceWorkflowItems(workspaceId: string) {
    return this.processRequest(
      this.workspacesClient.workflowItemsDetail(workspaceId),
    );
  }

  public async deleteWorkspaceWorkflowItem(
    workspaceId: string,
    workflowItemId: string,
  ) {
    return this.processRequest(
      this.workspacesClient.workflowItemsDelete(workspaceId, workflowItemId),
    );
  }

  public async publishWorkspaceWorkflowItem({
    workspaceId,
    workflowItemId,
    feedIds = [],
    workspaceMembershipIds = [],
  }: {
    workspaceId: string;
    workflowItemId: string;
    feedIds?: string[];
    workspaceMembershipIds?: string[];
  }) {
    return this.processRequest(
      this.workspacesClient.workflowItemsPublishCreate(
        workspaceId,
        workflowItemId,
        {
          feedIds,
          workspaceMembershipIds,
        },
      ),
    );
  }

  public async createWorkspaceInvitations(
    workspaceId: string,
    emails?: string[],
    phoneNumbers?: string[],
    sendEmail?: boolean,
    invitations?: WorkspaceInvitation[],
  ) {
    return this.processRequest(
      this.workspacesClient.directInvitationsCreate(workspaceId, {
        emails,
        phoneNumbers,
        sendEmail,
        invitations,
      }),
    );
  }

  public async getWorkspaceInvitations(workspaceId: string) {
    return this.processRequest(
      this.workspacesClient.invitationsDetail(workspaceId),
    );
  }

  // public async revokeWorkspaceInvitation(workspaceId: string) {
  // 	return this.processRequest(
  // 		this.workspacesClient.removeWorkspaceInvitation()
  // 	);
  // }

  public async deleteWorkspaceFeedItem(
    workspaceId: string,
    feedId: string,
    feedItemId: string,
  ) {
    return this.processRequest(
      this.workspacesClient.itemsDelete(workspaceId, feedId, feedItemId),
    );
  }

  public async publishToWorkspaceFeed({
    workspaceId,
    feedId,
    contentId,
    itemId,
    groupId,
    url,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    contentId: string;
    itemId?: string;
    groupId?: string;
    url?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) {
    itemId = itemId || cuid();

    return this.processRequest(
      this.workspacesClient.itemsCreate(workspaceId, feedId, {
        contentId,
        itemId,
        url,
        groupId,
        text,
        preferredLanguage,
        isSilent,
      }),
    );
  }

  public async publishTTS({
    workspaceId,
    feedId,
    contentId,
    itemId,
    groupId,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    contentId: string;
    itemId?: string;
    groupId?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) {
    itemId = itemId || cuid();

    return this.processRequest(
      this.workspacesClient.postWorkspaces(workspaceId, feedId, {
        contentId,
        itemId,
        groupId,
        text,
        preferredLanguage,
        isSilent,
      }),
    );
  }

  public async getWorkspaceFeedEvents(
    workspaceId: string,
    feedId: string,
    page = 0,
    pageSize = 10000,
  ) {
    return this.processRequest(
      this.workspacesClient.feedsEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceLatestFeedEvents(
    workspaceId: string,
    feedId: string,
    page = 0,
    pageSize = 10000,
  ) {
    return this.processRequest(
      this.workspacesClient.feedsLatestEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceOldestFeedEvents(
    workspaceId: string,
    feedId: string,
    page = 0,
    pageSize = 10000,
  ) {
    return this.processRequest(
      this.workspacesClient.feedsOldestEventsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async getWorkspaceFeedPermissions(
    workspaceId: string,
    feedId: string,
    page = 0,
    pageSize = 1000,
  ) {
    return this.processRequest(
      this.workspacesClient.feedsPermissionsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async createWorkspace(name: string) {
    return this.processRequest(
      this.workspacesClient.workspacesCreate({ name }),
    );
  }

  public async updateWorkspace(workspaceId: string, data: { name: string }) {
    return this.processRequest(
      this.workspacesClient.workspacesPartialUpdate(workspaceId, data),
    );
  }

  public async createWorkspaceFeed({
    workspaceId,
    title,
    isPrivate,
    readOnly,
    isSilent,
    autoSubscribe,
  }: {
    workspaceId: string;
    title: string;
    isPrivate: boolean;
    readOnly: boolean;
    isSilent?: boolean;
    autoSubscribe?: boolean;
  }) {
    return this.processRequest(
      this.workspacesClient.feedsCreate(workspaceId, {
        title,
        isPrivate,
        readOnly,
        isSilent,
        autoJoin: autoSubscribe,
      }),
    );
  }

  public async updateWorkspaceFeed({
    workspaceId,
    feedId,
    title,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    title: string;
    isSilent?: boolean;
  }) {
    return this.processRequest(
      this.workspacesClient.feedsPartialUpdate(workspaceId, feedId, {
        title,
        isSilent,
      }),
    );
  }

  public async createWorkspaceDm({
    workspaceId,
    workspaceMembershipIds,
  }: {
    workspaceId: string;
    workspaceMembershipIds: string[];
  }) {
    return this.processRequest(
      this.workspacesClient.directMessagesCreate(workspaceId, {
        membershipIds: workspaceMembershipIds,
      }),
    );
  }

  public async getWorkspaceFeedItems(
    workspaceId: string,
    feedId: string,
    page = 0,
    pageSize = 10,
  ) {
    return this.processRequest(
      this.workspacesClient.feedsItemsDetail(workspaceId, feedId, {
        page,
        pageSize,
      }),
    );
  }

  public async bootstrapWorkspaceFeedItems(
    workspaceId: string,
    page = 0,
    pageSize = 10000,
    timestamp?: string,
  ) {
    return this.processRequest(
      this.workspacesClient.feedItemsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async getFeedItemContent(
    workspaceId: string,
    itemIds: string[],
    language?: ContentLanguageRequest,
  ) {
    return this.processRequest(
      this.workspacesClient.feedItemContentCreate(workspaceId, {
        itemIds,
        language,
      }),
    );
  }

  public async getFeedItemOldestEvents(
    workspaceId: string,
    itemIds: string[],
    page = 0,
    pageSize = 1000,
  ) {
    return this.processRequest(
      this.workspacesClient.feedItemOldestEventsCreate(
        workspaceId,
        {
          itemIds,
        },
        { page, pageSize },
      ),
    );
  }

  public async addWorkspaceMembersToFeed(
    workspaceId: string,
    feedId: string,
    workspaceMembershipIds: string[],
  ) {
    return this.processRequest(
      this.workspacesClient.feedsWorkspaceMembershipsCreate(
        workspaceId,
        feedId,
        { workspaceMembershipIds },
      ),
    );
  }

  public async updateWorkspaceMember(
    workspaceId: string,
    workspaceMembershipIds: string[],
    role: WorkspaceRole,
  ) {
    return this.processRequest(
      this.workspacesClient.workspaceMembershipsUpdatePartialUpdate(
        workspaceId,
        {
          workspaceMembershipIds,
          role,
        },
      ),
    );
  }

  public async removeWorkspaceMember(
    workspaceId: string,
    workspaceMembershipIds: string[],
  ) {
    return this.processRequest(
      this.workspacesClient.workspaceMembershipsRemoveDelete(workspaceId, {
        workspaceMembershipIds,
        status: "inactive",
      }),
    );
  }

  public async muteUnmuteWorkspaceFeedMember(
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
    type: string,
  ) {
    return await this.processRequest(
      this.workspacesClient.feedsMuteUnmuteCreate(workspaceId, feedId, {
        workspaceMemberId,
        type,
      }),
    );
  }
  public async promoteDemoteMember(
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
    type: string,
  ) {
    return await this.processRequest(
      this.workspacesClient.feedsPromoteDemoteCreate(workspaceId, feedId, {
        workspaceMemberId,
        type,
      }),
    );
  }

  public async removeMemberFromFeed(
    workspaceId: string,
    feedId: string,
    workspaceMemberId: string,
  ) {
    return await this.processRequest(
      this.workspacesClient.feedsRemoveMemberCreate(workspaceId, feedId, {
        workspaceMemberId,
      }),
    );
  }

  public async bootstrapWorkspaces() {
    return this.processRequest(this.bootstrapClient.workspacesList());
  }

  public async tokenLogin(token: string) {
    return this.loginClient.tokenList({ token });
  }

  public async getUserInfo() {
    return this.processRequest(this.userInfoClient.userinfoList());
  }

  public async createContentReceipt({
    contentId,
    error,
    artifactId,
    artifactType,
  }: {
    contentId: string;
    error?: Error;
    artifactId?: string;
    artifactType?: ContentReceiptType;
  }) {
    // Turning this of for now since we don't care about tracking web receipts
    // return this.processRequest(
    // 	this.contentClient.receiptsCreate(contentId, {
    // 		contentId,
    // 		artifactId,
    // 		artifactType,
    //    timestamp: new Date().toISOString(),
    // 		deviceContext: {
    // 			surface: "web",
    // 			surfaceContext: window?.navigator?.userAgent,
    // 			surfaceBuild: buildVersion(),
    // 		},
    // 		// TODO get app context
    // 		error,
    // 	}),
    // );
  }

  public async createContentEvent({
    contentId,
    workspaceId,
    step,
    status,
    context,
    feedId,
    error,
  }: {
    contentId: string;
    step: ContentEventStep;
    status: ContentEventStepStatus;
    workspaceId?: string;
    context?: string;
    feedId?: string;
    error?: Error;
  }) {
    // console.log("createContentEvent", {
    //   contentId,
    //   workspaceId,
    //   step,
    //   status,
    //   context,
    //   feedId,
    //   error,
    // });
    return this.processRequest(
      this.contentClient.eventsCreate(contentId, {
        workspaceId,
        contentId,
        step,
        status,
        feedId,
        eventContext: context,
        timestamp: new Date().toISOString(),
        deviceContext: {
          surface: "web",
          surfaceContext: window?.navigator?.userAgent,
          surfaceBuild: buildVersion(),
          deviceRegistrationId: localStorage.getItem("deviceRegistrationId"),
        },
        // TODO get app context
        error,
      }),
    );
  }

  public async createContentPushReceipt({
    contentId,
    notificationType,
    feedId,
    workspaceId,
    itemId,
    sourceEventType,
  }: {
    contentId: string;
    notificationType: ContentPushNotificationType;
    feedId: string;
    workspaceId: string;
    itemId: string;
    sourceEventType: ContentPushSourceEventType;
  }) {
    return this.processRequest(
      this.contentClient.pushReceiptsCreate(contentId, {
        data: [
          {
            contentId,
            notificationType,
            feedId,
            workspaceId,
            itemId,
            sourceEventType,
            timestamp: new Date().toISOString(),
            deviceContext: {
              surface: "web",
              surfaceContext: window?.navigator?.userAgent,
              surfaceBuild: buildVersion(),
              deviceRegistrationId: localStorage.getItem(
                "deviceRegistrationId",
              ),
            },
          },
        ],
      }),
    );
  }

  public async refreshContent(uploadId?: string) {
    uploadId = uploadId || cuid();
    this.processRequest(this.contentClient.refreshCreate({ uploadId }));
    return uploadId;
  }

  public async unSubscribeFromWorkspaceFeed(
    workspaceId: string,
    feedId: string,
  ) {
    return this.processRequest(
      this.workspacesClient.unsubscribeFromFeedCreate(workspaceId, feedId),
    );
  }

  public async createAccountEvent(body: AccountEventRequest) {
    body.id = body.id || cuid();
    // console.log('account event', body);
    this.processRequest(this.accountEventClient.accountEventCreate(body));
    return body;
  }

  public async updateAccount(
    accountId: string,
    { fullName, email }: { fullName: string; email?: string },
  ) {
    return this.processRequest(
      this.accountClients.accountsPartialUpdate(accountId, {
        name: fullName,
        email,
      }),
    );
  }

  public async setPushNotificationToken({
    accountId,
    deviceToken,
    friendlyName,
  }: { accountId: string; deviceToken: string; friendlyName: string }) {
    return await this.processRequest(
      this.accountClients.pushnotificationdevicesUpdate(accountId, {
        enabled: true,
        surface: "web",
        deviceToken,
        friendlyName,
      }),
    );
  }

  public async deleteAccount(accountId: string) {
    await this.processRequest(this.accountClients.accountsDelete(accountId));
    return this.logout();
  }

  public async fetchPhoneToken(identity) {
    throw new Error("This API endpoint has been deleted");
    // return await this.processRequest(
    //   this.phoneClient.tokenCreate({ identity }),
    // );
  }

  public async deleteAppPhoneNumber(appPhoneNumberId: string) {
    throw new Error("This API endpoint has been deleted");
    // return await this.processRequest(
    //   this.phoneClient.numbersDelete(appPhoneNumberId),
    // );
  }

  public async processRequest<I, T>(request: Promise<HttpResponse<I, T>>) {
    try {
      const response = await request;
      this.emit("success", true);
      if (response.status === 401) {
        console.log("401 - redirecting to login", response);
        if (this.login) this.login();
      }
      return response.data;
    } catch (e) {
      this.emit("errorCodes", e);
      console.error("ERROR", e);
      switch (e.status) {
        case 401:
          console.log("401 from error - redirecting to login");
          if (this.login) this.login();
          break;
        case 418:
          return Promise.reject(e);
        case 500:
          throw new Error("Internal Server Error");
      }
    }
  }

  public async createPhoneNumber(forwardingPhoneNumber?: string) {
    throw new Error("This API endpoint has been deleted");
  }

  public async getWorkspaceTemplates({ workspaceId }: { workspaceId: string }) {
    return this.processRequest(
      this.workspacesClient.templatesDetail(workspaceId),
    );
  }

  public async createWorkspaceTemplate({
    workspaceId,
    name,
    template,
  }: {
    workspaceId: string;
    name: string;
    template: string;
  }) {
    return this.processRequest(
      this.workspacesClient.templatesCreate(workspaceId, { name, template }),
    );
  }

  public async updateWorkspaceTemplate({
    workspaceId,
    templateId,
    name,
    template,
  }: {
    workspaceId: string;
    templateId: string;
    name: string;
    template: string;
  }) {
    return this.processRequest(
      this.workspacesClient.templatesPartialUpdate(workspaceId, templateId, {
        name,
        template,
      }),
    );
  }

  public async deleteWorkspaceTemplate({
    workspaceId,
    templateId,
  }: {
    workspaceId: string;
    templateId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.templatesDelete(workspaceId, templateId),
    );
  }

  public async uploadLogs({
    deviceContext,
    appContext,
    logs,
    userMessage,
  }: UploadDeviceLogs) {
    return this.processRequest(
      this.logsClient.logsCreate({
        deviceContext,
        appContext,
        logs,
        userMessage,
      }),
    );
  }

  public createContentEventBatch(
    contentId: string,
    request: CreateContentEventRequests,
  ) {
    return this.processRequest(
      this.contentClient.eventsBatchCreate(contentId, request),
    );
  }

  public contentReceiptEventBatch(
    contentId: string,
    request: CreateContentRecieptRequests,
  ) {
    return this.processRequest(
      this.contentClient.receiptsBatchCreate(contentId, request),
    );
  }

  public accountEventBatch(request: AccountEventRequests) {
    return this.processRequest(
      this.accountEventsClient.accountEventsCreate(request),
    );
  }

  public heartbeat(workspaceId: string, heartbeat: HeartbeatRequest) {
    return this.processRequest(
      this.workspacesClient.heartbeatCreate(workspaceId, heartbeat),
    );
  }

  public getHandsFreeStatus(
    workspaceId: string,
    request: HandsFreeStatusRequest,
  ) {
    return this.processRequest(
      this.workspacesClient.handsfreestatusCreate(workspaceId, request),
    );
  }

  public getLocations(workspaceId: string, request: LocationsRequest) {
    return this.processRequest(
      this.workspacesClient.locationsCreate(workspaceId, request),
    );
  }

  public asyncEcho(data: string) {
    return this.processRequest(this.echoClient.asyncCreate({ data }));
  }

  public searchMessages(
    workspaceId: string,
    body: SearchMessagesRequest,
    params?: RequestParams,
  ): Promise<SearchMessageResponse> {
    return this.processRequest(
      this.searchClient.messagesCreate(workspaceId, body, params),
    );
  }

  public searchChannels(
    workspaceId: string,
    body: SearchFeedsRequest,
    params?: RequestParams,
  ): Promise<SearchFeedsResponse> {
    return this.processRequest(
      this.searchClient.feedsCreate(workspaceId, body, params),
    );
  }

  public joinFeedGroup({
    workspaceId,
    feedGroupId,
  }: {
    workspaceId: string;
    feedGroupId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedgroupsJoinCreate(workspaceId, feedGroupId),
    );
  }

  public leaveFeedGroup({
    workspaceId,
    feedGroupId,
  }: {
    workspaceId: string;
    feedGroupId: string;
  }) {
    return this.processRequest(
      this.workspacesClient.feedgroupsLeaveCreate(workspaceId, feedGroupId),
    );
  }

  public postActivity({
    workspaceId,
    workspaceMembershipId,
    drivingStatus,
    drivingStatusConfidence,
    deviceContext,
  }: {
    workspaceId: string;
    workspaceMembershipId: string;
    drivingStatus: DrivingStatus;
    drivingStatusConfidence: number;
    deviceContext?: DeviceContext;
  }) {
    return this.processRequest(
      this.activityClient.activityCreate({
        workspaceId,
        workspaceMembershipId,
        drivingStatus,
        drivingStatusConfidence,
        deviceContext,
      }),
    );
  }

  public async registerDevice({
    deviceRegistration,
  }: {
    deviceRegistration: DeviceRegistration;
  }) {
    return this.processRequest(
      this.deviceClient.registrationCreate({
        deviceRegistration,
      }),
    );
  }
  /////////////////////////////
  // BOOTSTRAP ENDPOINTS
  /////////////////////////////
  // feeds, feedItems, feedPermissions, workspaceMemberships, workspaceMembershipDrivingStatuses, templates, accounts, commandAliases, feedGroups, feedGroupMemberships, latestUnreadEventPerFeedForOrganizers, latestUnreadEventPerFeed

  public async latestUnreadEventPerFeedForOrganizers({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesLatestUnreadEventPerFeedForOrganizersDetail(
        workspaceId,
        {
          page,
          pageSize,
          timestamp,
        },
      ),
    );
  }

  public async latestUnreadEventPerFeed({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesLatestUnreadEventPerFeedDetail(
        workspaceId,
        {
          page,
          pageSize,
          timestamp,
        },
      ),
    );
  }

  public async bootstrapWorkspaceMemberships({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesWorkspaceMembershipsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedPermissions({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedPermissionsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedItemsPaginated({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedItemsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeeds({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceMembershipDrivingStatuses({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesWorkspaceMembershipDrivingStatusesDetail(
        workspaceId,
        {
          page,
          pageSize,
          timestamp,
        },
      ),
    );
  }

  public async bootstrapWorkspaceTemplates({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesTemplatesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceAccounts({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesAccountsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceCommandAliases({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesCommandAliasesDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedGroups({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedGroupsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }

  public async bootstrapWorkspaceFeedGroupMemberships({
    workspaceId,
    page,
    pageSize,
    timestamp,
  }: {
    workspaceId: string;
    page?: number;
    pageSize?: number;
    timestamp?: string;
  }) {
    return this.processRequest(
      this.bootstrapClient.workspacesFeedGroupMembershipsDetail(workspaceId, {
        page,
        pageSize,
        timestamp,
      }),
    );
  }
}
