import { FullInput } from "@/components/Utils";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { ActionContext } from "@/models/ActionsProvider";
import { SchedulingContext } from "@/models/SchedulingContextProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UppyContext } from "@/models/UppyContextProvider";
import { UxContext } from "@/models/UxStateProvider";
import { getChannelsWithPermissions } from "@/models/commonQueries";
import { Search } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import cuid from "cuid";
import Fuse from "fuse.js";
import { useContext, useMemo, useState } from "react";
import { VList } from "virtua";

export default function ScheduleMessageChannels() {
  const {
    goToNextStep,
    scheduledText,
    selectedChannels,
    setSelectedChannels,
    resetAll,
  } = useContext(SchedulingContext);
  const { setScheduleMessageModalOpen } = useContext(UxContext);
  const { publishBroadcast } = useContext(ActionContext);
  const { removeMetaData, uppyClient, addedFile } = useContext(UppyContext);
  const [sending, setSending] = useState<boolean>(false);
  const theme = useTheme();
  const [searchField, setSearchField] = useState<string>("");
  const { currentWorkspaceId: workspaceId, myCurrentWorkspaceMembership } =
    useContext(WorkspaceContext);

  const { rows: channelsWithPermissions } = useDrizzleSelect(
    getChannelsWithPermissions({
      workspaceId,
      myMembership: myCurrentWorkspaceMembership,
    }),
  );

  // fuse search engine
  const fuse = new Fuse(channelsWithPermissions, {
    // https://www.fusejs.io/examples.html#extended-search rules
    useExtendedSearch: true,
    keys: ["title", "id"],
  });

  const searchedChannels = useMemo(() => {
    if (searchField) {
      // `'` denotes includes this search text
      return fuse.search(`'${searchField}`)?.map((result) => result.item);
    }
    return channelsWithPermissions;
  }, [searchField, channelsWithPermissions, fuse]);

  const handleSendNow = async () => {
    try {
      setSending(true);
      let contentId = cuid();
      if (addedFile) {
        contentId = addedFile?.meta?.contentId;
        removeMetaData("feedId");
        await uppyClient.upload();
      }

      await publishBroadcast({
        workspaceId,
        contentId,
        feedIds: selectedChannels,
        inputText: scheduledText,
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setSending(false);
      setSelectedChannels([]);
      resetAll();
      setScheduleMessageModalOpen(false);
    }
  };

  const handleToggle = (id: string) => () => {
    const currentIndex = selectedChannels?.indexOf(id);
    const newChecked = [...selectedChannels];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedChannels(newChecked);
  };

  const secondaryAction = (channel) => {
    return (
      <Checkbox
        edge="end"
        onChange={handleToggle(channel?.id)}
        checked={selectedChannels?.indexOf(channel?.id) !== -1}
        inputProps={{ "aria-labelledby": channel?.id }}
        color="primary"
      />
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <FullInput
          id="channel-name"
          placeholder="Search channels"
          value={searchField}
          callback={(e) => setSearchField(e.target.value)}
          icon={
            <Box
              sx={{
                position: "absolute",
                top: "0.7rem",
                left: "0.5rem",
                zIndex: 12,
              }}
            >
              <Search
                sx={{
                  fontSize: "1.25rem",
                }}
              />
            </Box>
          }
        />
      </Box>
      <VList
        style={{
          width: "100%",
          height: "100%",
          maxHeight: 350,
          borderRadius: 8,
          border: `1.5px solid ${theme.palette.secondary.light}`,
          boxShadow: "0px 24px 40px 0px rgba(26, 26, 26, 0.16)",
          position: "relative",
          background: theme.palette.secondary.dark,
          overflow: searchedChannels?.length > 0 ? "auto" : "hidden",
          padding: "8px 10px",
        }}
      >
        {searchedChannels?.length > 0 ? (
          searchedChannels?.map((channel, index) => (
            <ListItem
              key={channel.id}
              secondaryAction={secondaryAction(channel)}
              disablePadding
            >
              <ListItemButton onClick={handleToggle(channel?.id)} disableRipple>
                <ListItemText id={channel?.id} primary={channel?.title} />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <ListItem
            key={"no-results"}
            disablePadding
            sx={{ textAlign: "center" }}
          >
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 700 }}>
                  No channels found
                </Typography>
              }
            />
          </ListItem>
        )}
      </VList>
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          gap: 2,
        }}
      >
        <LoadingButton
          loading={sending}
          variant="outlined"
          sx={{ order: { xs: 1, sm: 0 } }}
          onClick={handleSendNow}
          disabled={selectedChannels?.length === 0}
        >
          Send Now
        </LoadingButton>
        <Button
          variant="contained"
          color="primary"
          sx={{ order: { xs: 0, sm: 1 }, minWidth: "218px" }}
          onClick={goToNextStep}
          disabled={selectedChannels?.length === 0 || sending}
        >
          Schedule for later
        </Button>
      </Stack>
    </>
  );
}
