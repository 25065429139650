import { db } from "@/db/db";
import { useDrizzleQuery } from "@/db/drizzleUtils";
import { transcription } from "@/db/schema";
import { Item, Transcription } from "@/db/types";
import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { eq } from "drizzle-orm";
import { useState } from "react";
import "regenerator-runtime/runtime";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabContent(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

type ModelGroup = {
  model: string;
  items: Transcription[];
};

function groupTranscriptionByModel(transcriptions: Transcription[]) {
  return transcriptions.reduce((acc: ModelGroup[], item: Transcription) => {
    let modelGroup: ModelGroup = acc.find(
      (group) => group.model === item.backendModel,
    );
    if (!modelGroup) {
      modelGroup = {
        model: item.backendModel,
        items: [],
      };
      acc.push(modelGroup);
    }

    return acc;
  }, []);
}

type LanguageGroup = {
  language: string;
  items: Transcription[];
};

function groupTranscriptionByLanguage(transcriptions: Transcription[]) {
  return transcriptions.reduce((acc: LanguageGroup[], item: Transcription) => {
    let languageGroup: LanguageGroup = acc.find(
      (group) => group.language === item.language,
    );
    if (!languageGroup) {
      languageGroup = {
        language: item.language,
        items: [],
      };
      acc.push(languageGroup);
    }
    languageGroup.items.push(item);

    return acc;
  }, []);
}

export default function TranscriptionsTable({ item }: { item: Item }) {
  const { rows: allTranscriptions } = useDrizzleQuery(
    db.query.transcription.findMany({
      where: eq(transcription.contentId, item.id),
    }),
  );

  const transcriptions =
    allTranscriptions?.sort((a, b) => {
      if (a.transcriptionType > b.transcriptionType) return 1;
      if (a.transcriptionType < b.transcriptionType) return -1;

      if (a.format > b.format) return 1;
      if (a.format < b.format) return -1;

      if (a.language > b.language) return 1;
      if (a.language < b.language) return -1;

      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;
      return 0;
    }) || [];

  // const groupedTranscriptionsByModel =
  // 	groupTranscriptionByLanguage(transcriptions);
  const groupedTranscriptionsByLanguage =
    groupTranscriptionByLanguage(transcriptions);

  // groupedTranscriptionsByLanguage.unshift({ language: "All", items: transcriptions });
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {groupedTranscriptionsByLanguage.map((transcript, idx) => (
          <Tab label={transcript.language} key={`tab-${idx}`} />
        ))}
      </Tabs>
      {groupedTranscriptionsByLanguage.map((transcript, idx) => (
        <TabContent value={value} index={idx} key={`content-${idx}`}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {/*<TableCell>Updated</TableCell>*/}
                  <TableCell>Format</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Translated From</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Text</TableCell>
                  <TableCell>Raw Data</TableCell>
                  <TableCell>Transcription ID</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Confidence</TableCell>
                  <TableCell>Execution Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transcript.items.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    {/* <TableCell>{row.type}</TableCell> */}
                    {/*<TableCell>{row.updatedAt}</TableCell>*/}
                    <TableCell>{row.format}</TableCell>
                    <TableCell>{row.backendModel}</TableCell>
                    <TableCell>{row.translatedFrom}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>
                      {row.format.match(/ssml/i) ? (
                        <div style={{ maxWidth: "500px" }}>
                          {row.transcriptionContent}
                        </div>
                      ) : (
                        <div
                          style={{ maxWidth: "500px" }}
                          dangerouslySetInnerHTML={{
                            __html: row.transcriptionContent,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href={row.url} rel="noreferrer">
                        Json Data
                      </a>
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>{row.confidence}</TableCell>
                    <TableCell>{row.executionTime}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabContent>
      ))}
    </>
  );
}
