import { db } from "@/db/db";

import {
  account,
  accountEvent,
  broadcastAction,
  broadcastRecipient,
  directWsInvitation,
  feed,
  feedGroup,
  feedGroupMembership,
  item,
  permission,
  scheduleTrigger,
  template,
  workflowItem,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
  wsHandsFreeStatus,
} from "@/db/schema";
import { Account, WorkspaceMembership } from "@/db/types";
import { useLiveQuery } from "@electric-sql/pglite-react";
import {
  and,
  asc,
  desc,
  eq,
  getTableColumns,
  gte,
  ilike,
  inArray,
  isNotNull,
  isNull,
  or,
  sql,
} from "drizzle-orm";
import { FullItem } from "./FeedContextProvider";
import raw = sql.raw;
import { logger } from "@/utils/logging";

const allEmpty = false;
const verbose = false;

function logQuery(name: string, query: any, ...args: any[]) {
  const s = query.toSQL();
  const sq = s.sql;
  const params = s.params;
  const emptyParams =
    params.filter((p) => p === undefined || p === null || p === "").length > 0;
  if (emptyParams) {
    console.error("Empty Params", { name, params, sq }, ...args);
  }
  if (verbose) {
    console.log("Common Queries", { name, params, sq }, ...args);
  }
}

export const emptyQuery = {
  toSQL: () => {
    return {
      sql: `select feed.id from feed where 1 = 0`,
      params: [],
    };
  },
  execute: () => Promise<[]>,
};

export function myWorkspaceMembershipsQuery({
  accountId,
  currentWorkspaceId,
}: { accountId?: string; currentWorkspaceId?: string }) {
  const query = db
    .select()
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, currentWorkspaceId),
        eq(workspaceMembership.accountId, accountId),
        eq(workspaceMembership.status, "active"),
      ),
    );

  if (!accountId || !currentWorkspaceId) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Workspace Memberships", query);
  return query;
}

// `
// SELECT
//     feed_group.id,
//     feed_group.name,
//     feed_group.updatedAt,
//     feed_group_membership.feedId,
//     (GREATEST(
//             GREATEST(coalesce(item.createdAt, '-infinity'::timestamp)),
//             coalesce(feed.updatedAt, '-infinity'::timestamp),
//             coalesce(feed.latestActivity, '-infinity'::timestamp)
//      )) as latestActivity
// FROM feed_group
//     JOIN feed_group_membership on feed_group.id = feed_group_membership.groupId
//     JOIN feed on feed.id = feed_group_membership.feedId
//     JOIN permission ON permission.feedId = feed.id and permission.name = 'read' and permission.enabled = true
//     LEFT OUTER JOIN item ON feed.id = item.feedId
// WHERE
//     feed_group.workspaceId = ?
// GROUP BY feed_group.id
// `
//   return db.liveRaw({
//     sql,
//     args: [workspaceId, myCurrentWorkspaceMembership?.id],
//   });
// }, [workspaceId, myCurrentWorkspaceMembership]);

export function groupsQuery({ workspaceId }: { workspaceId: string }) {
  const query = db
    .select({
      id: feedGroup.id,
      name: feedGroup.name,
      updatedAt: feedGroup.updatedAt,
      feedId: feedGroupMembership.feedId,
      latestActivity: sql`GREATEST(
        (coalesce(MAX(${item.createdAt}), '-infinity'::timestamp),
        coalesce(${feed.updatedAt}, '-infinity'::timestamp),
      ) as latestActivity`,
    })
    .from(feedGroup)
    .innerJoin(
      feedGroupMembership,
      eq(feedGroup.id, feedGroupMembership.groupId),
    )
    .innerJoin(feed, eq(feed.id, feedGroupMembership.feedId))
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feed.id),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .leftJoin(item, eq(feed.id, item.feedId))
    .where(eq(feedGroup.workspaceId, workspaceId))
    .groupBy(feedGroup.id);

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Groups", query);
  return query;
}

// `SELECT
//     feed.id,
//     feed.title,
//     feed.workspaceId,
//     feed.updatedAt,
//     feed_group_membership.groupId as groupId,
//     (GREATEST(
//             coalesce(feed.updatedAt, '-infinity'::timestamp),
//             coalesce(feed.latestActivity, '-infinity'::timestamp),
//             coalesce(item.createdAt, '-infinity'::timestamp)
//      )) as latestActivity
// FROM
//     feed
//     LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
//     LEFT JOIN permission on feed.id = permission.feedId
//     LEFT OUTER JOIN item ON feed.id = item.feedId
//     LEFT OUTER JOIN feed_group on feed_group.id = feed_group_membership.groupId
// WHERE
//     feed.workspaceId = ?
//     AND
//     permission.enabled = 1
//     AND
//     permission.name = 'read'
//     AND permission.enabled = true
//     AND permission.workspace_membershipId = ?
// GROUP BY feed.id
// ORDER BY feed_group_membership.groupId, latestActivity ASC
// `;

export function groupedFeedsQuery({
  workspaceId,
  myCurrentWorkspaceMembershipId,
}: {
  workspaceId?: string;
  myCurrentWorkspaceMembershipId?: string;
}) {
  const query = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      updatedAt: feed.updatedAt,
      groupId: feedGroupMembership.groupId,
      latestActivity: sql`GREATEST(
        coalesce(MAX(${item.createdAt}), '-infinity'::timestamp),
        coalesce(${feed.updatedAt}, '-infinity'::timestamp),
      ) as latestActivity
      `,
    })
    .from(feed)
    .innerJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feed.id),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .leftJoin(item, eq(feed.id, item.feedId))
    .where(
      and(
        eq(feed.workspaceId, workspaceId),
        inArray(
          feed.id,
          db
            .select({ feedId: permission.feedId })
            .from(permission)
            .where(
              and(
                eq(
                  permission.workspaceMembershipId,
                  myCurrentWorkspaceMembershipId,
                ),
                eq(permission.name, "read"),
                eq(permission.enabled, true),
              ),
            ),
        ),
        eq(feed.isDm, false),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
        eq(permission.enabled, true),
      ),
    );
  if (!workspaceId || !myCurrentWorkspaceMembershipId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Grouped Feeds", query);
  return query;
}

export function myActiveFeedsQuery({
  workspaceId,
  myCurrentWorkspaceMembershipId,
}: { workspaceId?: string; myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      updatedAt: feed.updatedAt,
      // groupId: sql`${feedGroup.id} as groupId`,
      groupId: feedGroupMembership.groupId,
      latestActivity: sql`GREATEST( coalesce(MAX(${item.createdAt}), '-infinity'::timestamp), coalesce(${feed.updatedAt}, '-infinity'::timestamp)) as latestActivity`,
    })
    .from(feed)
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
        eq(permission.enabled, true),
        eq(permission.name, "read"),
      ),
    )
    .leftJoin(item, eq(feed.id, item.feedId))
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId))
    .groupBy(
      feed.id,
      feed.title,
      feed.workspaceId,
      feed.updatedAt,
      feedGroupMembership.groupId,
      // sql`latestActivity`,
    )
    .where(and(eq(feed.workspaceId, workspaceId), eq(feed.isDm, false)));

  if (!workspaceId || !myCurrentWorkspaceMembershipId) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Active Feeds", query);
  return query;
}

export function unreadFeedIdsQuery({
  myCurrentWorkspaceMembershipId,
}: { myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .selectDistinct({ feedId: item.feedId })
    .from(item)
    .innerJoin(feed, eq(feed.id, item.feedId))
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feed.id),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
      ),
    )
    .where(and(eq(item.unread, true), isNotNull(item.deletedAt)));
  if (!myCurrentWorkspaceMembershipId || allEmpty) {
    logger(["EMPTY QUERY FOR Unread Feed Ids", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Unread Feed Ids", query);
  return query;
}

export function myFeedPermissionsQuery({
  currentFeedId,
  myCurrentWorkspaceMembershipId,
}: { currentFeedId?: string; myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .select()
    .from(permission)
    .where(
      and(
        eq(permission.feedId, currentFeedId),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
        eq(permission.enabled, true),
      ),
    );
  if (!currentFeedId || !myCurrentWorkspaceMembershipId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Feed Permissions", query);
  return query;
}

export function feedPermissionsQuery({ feedId }: { feedId?: string }) {
  const query = db
    .select()
    .from(permission)
    .where(and(eq(permission.feedId, feedId)));

  if (!feedId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Feed Permissions", query);
  return query;
}

export function justWorkspaceMembershipsQuery({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .select()
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    );
  if (!workspaceId || allEmpty) {
    console.log("EMPTY QUERY FOR", query.toSQL());
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Just Workspace Memberships", query);
  return query;
}

export function workspaceMembershipsQuery({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .select({
      accountId: workspaceMembership.accountId,
      role: workspaceMembership.role,
      membershipId: sql`${workspaceMembership.id} as membershipId`,
      email: sql`COALESCE(${account.email}, ${directWsInvitation.email}) as email`,
      phoneNumber: sql`COALESCE(${account.phoneNumber}, ${directWsInvitation.phoneNumber}) as "phoneNumber"`,
      name: sql`COALESCE(${account.name}, ${directWsInvitation.name}) as name`,
      avatarColor: account.avatarColor,
    })
    .from(workspaceMembership)
    .leftJoin(account, eq(workspaceMembership.accountId, account.id))
    .leftJoin(
      directWsInvitation,
      eq(workspaceMembership.id, directWsInvitation.workspaceMembershipId),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    );
  if (!workspaceId || allEmpty) {
    console.log("EMPTY QUERY FOR", query.toSQL());
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Memberships", query);
  return query;

  // const { results: workspaceMembers, error } = useLiveQuery(() => {
  // 	if (!workspaceId) {
  // 		return;
  // 	}
  // 	return db.liveRaw({
  // 		sql: `
  // 			SELECT
  // 				workspace_membership.accountId as accountId,
  // 				workspace_membership.role,
  // 				workspace_membership.id as membershipId,
  // 				COALESCE(account.email, direct_ws_invitation.email) as email,
  // 				COALESCE(account.phoneNumber, direct_ws_invitation.phoneNumber) as phoneNumber,
  // 				COALESCE(account.name, direct_ws_invitation.name) as name,
  // 				account.avatarColor as avatarColor
  // 			FROM
  // 				workspace_membership
  // 			LEFT JOIN
  // 				account
  // 			ON
  // 				workspace_membership.accountId = account.id
  // 			LEFT JOIN
  // 				direct_ws_invitation
  // 			ON
  // 				workspace_membership.id = direct_ws_invitation.workspaceMembershipId
  // 			WHERE
  // 				workspace_membership.workspaceId = ?
  // 			AND
  // 				workspace_membership.status = 'active'`,
  // 		args: [workspaceId],
  // 	});
  // }, [workspaceId]);
  // return { workspaceMembers, error } as {
  // 	workspaceMembers: WorkspaceMember[]
  // 	error: any
  // }
}

// function getLatestFeedItemEvents({ itemRecord }: { itemRecord: FullItem }) {
//   const query = db
//     .select()
//     .from(accountEvent)
//     .where(
//       and(
//         eq(accountEvent.itemId, itemRecord.id), // Replace with your actual itemId value
//       ),
//     );

//   if (!itemRecord.feedId || !itemRecord.id || allEmpty) {
//     console.log("EMPTY QUERY FOR", query.toSQL());
//     return emptyQuery as unknown as typeof query;
//   }
//   logQuery("Latest Feed Item Events", query);
//   return query;
// }

export function getChannelsWithPermissions({
  workspaceId,
  myMembership,
}: { workspaceId: string; myMembership: WorkspaceMembership }) {
  const query = db
    .select({
      ...getTableColumns(feed),
      permission_name: sql`${permission.name} as permission_name`,
      wsmid: sql`${permission.workspaceMembershipId} as wsmid`,
      name: account.name,
      email: account.email,
      // title: sql`IFNULL(${feed.title}, 'DM: ' || ${account.name})`,
    })
    .from(feed)
    .innerJoin(permission, eq(permission.feedId, feed.id))
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .innerJoin(account, eq(account.id, workspaceMembership.accountId))
    .where(
      and(
        eq(feed.workspaceId, workspaceId),
        eq(permission.name, "write"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myMembership.id),
      ),
    )
    // .groupBy(feed.id)
    .orderBy(asc(feed.isDm), asc(feed.title));

  if (!workspaceId || !myMembership?.id || allEmpty) {
    console.log("EMPTY QUERY FOR", query.toSQL());
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Channels with Permissions", query);
  return query;
  //  const { results: channelsWithPermissions, error } = useLiveQuery(() => {
  //     if (!workspaceId || !myMembership?.workspaceId) {
  //       return;
  //     }
  //     return db.liveRaw({
  //       sql: `
  // 	SELECT
  //     feed.*,
  //     permission.name as permission_name,
  //     permission.workspace_membershipId as wsmid,
  //     account.name,
  //     account.email,
  //     IFNULL(feed.title, 'DM: ' || account.name) as title
  // 	FROM
  // 	    feed
  // 	JOIN permission ON permission.feedId = feed.id
  // 	JOIN workspace_membership ON workspace_membership.id = permission.workspace_membershipId
  //   JOIN account ON account.id = workspace_membership.accountId
  // 	WHERE
  // 	    feed.workspaceId = ?
  // 				AND
  //       permission.name = 'write'
  // 				AND
  //       permission.enabled = 1
  //         AND
  //       permission.workspace_membershipId = ?
  // 	GROUP BY feed.id
  // 	ORDER BY
  //       feed.isDm ASC,
  // 	    feed.title ASC
  // 			`,
  //       args: [workspaceId, myMembership?.id],
  //     });
  //   }, [workspaceId, myMembership]);

  //   return { channelsWithPermissions, error } as {
  //     channelsWithPermissions: ChannelWithPermission[];
  //     error: any;
  //   };
}

type ChannelItemResponse = {
  id: string;
  feedId: string;
  groupId: string;
  unread: boolean;
  title: string;
  feedGroupId: string;
  joined: string;
};

export const fetchAllGroupedChannelsQuery = async ({
  workspaceMembershipId,
  groupId,
}: {
  workspaceMembershipId: string;
  groupId: string;
}) => {
  const query = db
    .select({
      id: item.id,
      feedId: item.feedId,
      groupId: item.groupId,
      unread: item.unread,
      title: feed.title,
      feedGroupId: sql`${feedGroupMembership.groupId} as feedGroupId`,
      joined: permission.workspaceMembershipId,
    })
    .from(item)
    .innerJoin(feed, eq(feed.id, item.feedId))
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId))
    .where(and(eq(item.unread, true), eq(feedGroupMembership.groupId, groupId)))
    .groupBy(item.id);

  if (!workspaceMembershipId || !groupId || allEmpty) {
    console.log("EMPTY QUERY FOR", query.toSQL());
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Fetch all grouped channels", query);
  return query;
  // const sql = `
  //   select
  //       item.id,
  //       item.feedId,
  //       item.groupId,
  //       item.unread,
  //       feed.title,
  //       feed_group_membership.groupId as feedGroupId,
  //       permission.workspace_membershipId as joined
  //    from item
  //       join feed on feed.id = item.feedId
  //       JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
  //       LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
  //       LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
  //    where
  //       unread = 1
  //       AND feed_group_membership.groupId = $2
  //    GROUP by item.id
  //   `;
  // return (await db.raw({
  //   sql,
  //   args: [workspaceMembershipId, groupId],
  // })) as unknown as ChannelItemResponse[];
};

export const fetchAllNonGroupedChannelsQuery = async ({
  workspaceMembershipId,
}: { workspaceMembershipId: string }) => {
  const query = db
    .select({
      id: item.id,
      feedId: item.feedId,
      groupId: item.groupId,
      unread: item.unread,
      title: feed.title,
      feedGroupId: sql`${feedGroupMembership.groupId} as feedGroupId`,
      joined: permission.workspaceMembershipId,
    })
    .from(item)
    .innerJoin(feed, eq(feed.id, item.feedId))
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId))
    .where(and(eq(item.unread, true), isNull(feedGroupMembership.groupId)))
    .groupBy(item.id);
  if (!workspaceMembershipId || allEmpty) {
    console.log("EMPTY QUERY FOR", query.toSQL());
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Fetch all non grouped channels", query);
  return query;
  // const sql = `
  //     select
  //         item.id,
  //         item.feedId,
  //         item.groupId,
  //         item.unread,
  //         feed.title,
  //         feed_group_membership.groupId as feedGroupId,
  //         permission.workspace_membershipId as joined
  //     from item
  //         join feed on feed.id = item.feedId
  //         JOIN permission  ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
  //         LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
  //         LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
  //     where
  //         unread = 1
  //         AND feed_group_membership.groupId IS NULL
  //     GROUP by item.id
  // `;
  // return (await db.raw({
  //   sql,
  //   args: [workspaceMembershipId],
  // })) as unknown as ChannelItemResponse[];
};

export type ChannelOrderType = "driver-activity" | "alpha-asc";
export type ChannelTypeType =
  | "my-channels"
  | "my-unreads"
  | "all-channels"
  | "all-unreads";
export type ChannelList = {
  id: string;
  title: string;
  workspaceId: string;
  isPrivate: boolean;
  enabled: boolean;
  groupId: string;
  groupName: string;
  latestActivity: string;
  joined: boolean;
  unread: boolean;
  driverActivity: string;
  aliasChannel: string;
};

const buildSQL = (
  channelType: ChannelTypeType,
  channelOrder: ChannelOrderType,
  searchValue: string,
  limitedMember: boolean,
): string => {
  // if you are not a limited member and the active tab is all-channels, we need to left join the permission table
  const joinOrLeftJoin =
    !limitedMember || channelType === "all-channels" ? "LEFT JOIN" : "JOIN";

  // search query LIKE as ft search
  const searchWhereClause =
    searchValue?.length > 0
      ? `AND (REPLACE(feed.title,' ','') like '%${searchValue}%' OR feed.title like '%${searchValue}%' OR feed.id LIKE '%${searchValue}%' OR feed_group.name LIKE '%${searchValue}%' OR REPLACE(feed_group.name,' ','') LIKE '%${searchValue}%')`
      : "";

  // keep alive is a special flag that will keep the My Unreads active after marking all items as read
  const unreadQuery = channelType === "my-unreads" ? "AND (unread = 1)" : "";

  // basic channel sorting -> Driver activity, alias channel and then  latestActivity || alpha-asc
  // if joined is NULL sort to the bottom
  const channelSortOrder =
    channelOrder === "driver-activity"
      ? "ORDER BY IFNULL(joined,9999), driverActivity DESC, aliasChannel desc, latestActivity DESC"
      : "ORDER BY lower(feed.title) ASC";

  // return the sql query
  return `
    SELECT
        feed.id,
        feed.title,
        feed.isPrivate,
        feed.workspaceId,
        permission.enabled,
        feed_group_membership.groupId,
        feed_group.name as groupName,
        permission.enabled as joined,
        MAX(coalesce(feed.updatedAt, '0'),coalesce(MAX(item.createdAt), '0')) as latestActivity,
        EXISTS(select i.id from item as i where i.unread = true AND feed.id = i.feedId LIMIT 1) as unread,
        (SELECT MAX(item.createdAt) FROM item JOIN workspace_membership ON item.accountId = workspace_membership.accountId WHERE item.feedId = feed.id AND workspace_membership.role = 'limitedMember') as driverActivity,
        (SELECT alias FROM workspace_command_alias WHERE workspace_command_alias.feedId = feed.id LIMIT 1) as aliasChannel
    FROM
        feed
          LEFT JOIN item  ON feed.id = item.feedId
          ${joinOrLeftJoin} permission ON feed.id = permission.feedId AND permission.workspace_membershipId = $1 AND permission.name = 'read' AND permission.enabled = true
          LEFT JOIN feed_group_membership on feed.id = feed_group_membership.feedId
          LEFT JOIN feed_group on feed_group.id = feed_group_membership.groupId
    WHERE
        feed.workspaceId = $2
      AND
        feed.isDm = 0
      AND
        (
            (feed.isPrivate = 1 AND permission.enabled = true)
                OR
            (feed.isPrivate = 0)
        )
        ${searchWhereClause}
        ${unreadQuery}
    group by feed.id
    ${channelSortOrder}`;
};

const fetchChannels = ({
  db,
  channelOrder,
  channelType,
  workspaceId,
  workspaceMembershipId,
  searchValue,
  limitedMember,
}): ChannelList[] => {
  const { rows: data } = useLiveQuery(
    db.liveRaw({
      sql: buildSQL(channelType, channelOrder, searchValue, limitedMember),
      args: [workspaceMembershipId, workspaceId],
    }),
    [
      channelOrder,
      channelType,
      workspaceId,
      workspaceMembershipId,
      searchValue,
      limitedMember,
    ],
  );

  logger(["Common Queries", "Fetch channels"]);
  return data as ChannelList[];
};

export const fetchChannelList = ({
  channelOrder,
  channelType,
  workspaceId,
  workspaceMembershipId,
  searchValue,
  limitedMember,
  myAccount,
}: {
  channelOrder: ChannelOrderType;
  channelType: ChannelTypeType;
  workspaceId: string;
  workspaceMembershipId: string;
  searchValue: string;
  limitedMember: boolean;
  myAccount: Account;
}) => {
  const driverActivity = sql`coalesce(MAX(${item.createdAt}), '-infinity'::timestamp)`;

  // set where clause to include feed.isDm = false
  const notDM = eq(feed.isDm, false);
  const titleNotNull = isNotNull(feed.title);

  // set the where clause to have notDm or searchValue
  const whereClause = [notDM, titleNotNull];
  if (searchValue?.length > 0) {
    whereClause.push(
      or(
        ilike(feed.title, `%${searchValue}%`),
        ilike(feedGroup.name, `%${searchValue}%`),
      ),
    );
  }

  const latestActivity = sql`GREATEST(${feed.updatedAt} , MAX(${item.createdAt}))`;

  let scratch = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      isPrivate: feed.isPrivate,
      joined: sql`permission.enabled as joined`,
      enabled: permission.enabled,
      groupId: feedGroupMembership.groupId,
      groupName: sql`${feedGroup.name} as "groupName"`,
      driverActivity: driverActivity.as("driverActivity"),
      latestActivity: latestActivity.as("latestActivity"),
      unread: sql`MAX(${item.createdAt}) FILTER(where ${item.unread} = true) as unread`,
      aliasChannel: sql`${workspaceCommandAlias.alias} as "aliasChannel"`,
    })
    .from(feed);

  switch (channelType) {
    case "my-channels":
      scratch
        .leftJoin(item, eq(feed.id, item.feedId))
        .innerJoin(
          permission,
          and(
            eq(feed.id, permission.feedId),
            eq(permission.workspaceMembershipId, workspaceMembershipId),
            eq(permission.name, "read"),
            eq(permission.enabled, true),
          ),
        );
      break;
    case "my-unreads":
      scratch
        .innerJoin(
          permission,
          and(
            eq(feed.id, permission.feedId),
            eq(permission.workspaceMembershipId, workspaceMembershipId),
            eq(permission.name, "read"),
            eq(permission.enabled, true),
          ),
        )
        .innerJoin(item, and(eq(feed.id, item.feedId), eq(item.unread, true)));
      break;
    case "all-channels":
      scratch
        .leftJoin(item, eq(feed.id, item.feedId))
        .leftJoin(
          permission,
          and(
            eq(feed.id, permission.feedId),
            eq(permission.workspaceMembershipId, workspaceMembershipId),
            eq(permission.name, "read"),
            eq(permission.enabled, true),
          ),
        );
      whereClause.push(
        or(eq(permission.enabled, true), eq(feed.isPrivate, false)),
      );
      break;
  }
  scratch
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId))
    .leftJoin(workspaceCommandAlias, eq(workspaceCommandAlias.feedId, feed.id))
    .leftJoin(
      workspaceMembership,
      and(eq(workspaceMembership.accountId, item.accountId)),
    );

  scratch
    .where(and(...whereClause))
    .groupBy(
      feed.id,
      feedGroupMembership.groupId,
      feedGroup.name,
      permission.enabled,
      sql`joined`,
      sql`"aliasChannel"`,
    );

  // nulls last: https://orm.drizzle.team/docs/select#order-by
  if (channelOrder === "driver-activity") {
    scratch.orderBy(
      desc(driverActivity),
      desc(sql`"aliasChannel"`),
      desc(latestActivity),
    );
  } else {
    scratch.orderBy(asc(sql`lower(${feed.title})`));
  }

  if (!workspaceId || !workspaceMembershipId || !myAccount?.id || allEmpty) {
    logger(["EMPTY QUERY FOR", scratch.toSQL()]);
    return emptyQuery as unknown as typeof scratch;
  }
  logQuery("Fetch channel list", scratch);
  return scratch;
};

export function findWorkspaceQuery({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select()
    .from(workspace)
    .where(and(eq(workspace.id, workspaceId)));

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Find Workspace", query);
  return query;
}

export function currentFeedQuery({
  currentFeedId,
}: { currentFeedId?: string }) {
  const query = db.select().from(feed).where(eq(feed.id, currentFeedId));

  if (!currentFeedId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Current Feed", query);
  return query;
}

function workspaceMemberHandsFreeStatusQuery({
  accountId,
  currentWorkspaceId,
}: {
  accountId?: string;
  currentWorkspaceId?: string;
}) {
  const query = db
    .select()
    .from(wsHandsFreeStatus)
    .innerJoin(
      workspaceMembership,
      and(
        eq(wsHandsFreeStatus.id, workspaceMembership.id),
        eq(workspaceMembership.status, "active"),
        eq(workspaceMembership.workspaceId, currentWorkspaceId),
        eq(workspaceMembership.accountId, accountId),
      ),
    )
    .innerJoin(account, eq(workspaceMembership.accountId, account.id));
  if (!accountId || !currentWorkspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Member Hands Free Status", query);
  return query;
}

export function currentFeedPendingInvitesSelect({
  currentFeedId,
}: {
  currentFeedId?: string;
}) {
  const query = db
    .select({
      ...getTableColumns(directWsInvitation),
    })
    .from(directWsInvitation)
    .innerJoin(
      permission,
      eq(
        directWsInvitation.workspaceMembershipId,
        permission.workspaceMembershipId,
      ),
    )
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .where(
      and(
        eq(permission.feedId, currentFeedId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        isNull(directWsInvitation.claimedBy),
        eq(workspaceMembership.status, "active"),
      ),
    );

  if (!currentFeedId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Current Feed Pending Invites", query);
  return query;
}

export function workflowItemsSelect({ workspaceId }: { workspaceId?: string }) {
  const query = db
    .selectDistinct({
      ...getTableColumns(workflowItem),
    })
    .from(workflowItem)
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
      ),
    )
    .orderBy(desc(workflowItem.createdAt));

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workflow Items", query);
  return query;
}

export function broadcastActionSelect({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .selectDistinct({
      ...getTableColumns(broadcastAction),
    })
    .from(broadcastAction)
    .innerJoin(
      workflowItem,
      eq(broadcastAction.workflowItemId, workflowItem.id),
    )
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
      ),
    );

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Broadcasts", query);
  return query;
}

export function scheduleTriggerSelect({
  workspaceId,
  date,
}: {
  workspaceId?: string;
  date?: string;
}) {
  const query = db
    .selectDistinct({
      ...getTableColumns(scheduleTrigger),
    })
    .from(scheduleTrigger)
    .innerJoin(
      broadcastAction,
      eq(scheduleTrigger.broadcastActionId, broadcastAction.id),
    )
    .innerJoin(
      workflowItem,
      eq(broadcastAction.workflowItemId, workflowItem.id),
    )
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
        or(
          gte(scheduleTrigger.oneTimeSchedule, date),
          isNotNull(scheduleTrigger.cronSchedule),
        ),
      ),
    );

  if (!workspaceId || !date || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Schedule Triggers", query);
  return query;
}

export function workspaceMemberCountResponseSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      memberCount: sql`COUNT(${workspaceMembership.accountId}) as "memberCount"`,
    })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNotNull(workspaceMembership.accountId),
      ),
    );

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Member Count", query);
  return query;
}

export function workspaceInviteCountResponseSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      inviteCount: sql`COUNT(${directWsInvitation.id}) as "inviteCount"`,
    })
    .from(directWsInvitation)
    .innerJoin(
      workspaceMembership,
      eq(directWsInvitation.workspaceMembershipId, workspaceMembership.id),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNull(directWsInvitation.claimedBy),
      ),
    );

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Invite Count", query);
  return query;
}

export function workspaceInviteTableSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      ...getTableColumns(directWsInvitation),
      contact: sql`COALESCE(${directWsInvitation.email}, ${directWsInvitation.phoneNumber}) as contact`,
      date: sql`${directWsInvitation.createdAt} as date`,
      membershipStatus: sql`${workspaceMembership.status} as "membershipStatus"`,
      role: sql`${workspaceMembership.role} as "role"`,
    })
    .from(directWsInvitation)
    .innerJoin(
      workspaceMembership,
      eq(directWsInvitation.workspaceMembershipId, workspaceMembership.id),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNull(directWsInvitation.claimedBy),
      ),
    )
    .orderBy(desc(directWsInvitation.createdAt));

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Invites", query);
  return query;
}

export function workspaceMembersTableSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      id: account.id,
      email: account.email,
      phoneNumber: account.phoneNumber,
      name: account.name,
      mine: account.mine,

      role: workspaceMembership.role,
      membershipId: sql`${workspaceMembership.id} as membershipId`,
    })
    .from(workspaceMembership)
    .innerJoin(account, eq(workspaceMembership.accountId, account.id))
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    )
    .orderBy(asc(account.name));

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Members", query);
  return query;
}

export function cachedTemplatesSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select()
    .from(template)
    .where(
      and(eq(template.workspaceId, workspaceId), isNull(template.deletedAt)),
    );

  if (!workspaceId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Cached Templates", query);
  return query;
}

export function broadcastRecipientsSelect({
  broadcastId,
}: {
  broadcastId?: string;
}) {
  const query = db
    .select()
    .from(broadcastRecipient)
    .where(eq(broadcastRecipient.broadcastId, broadcastId));

  if (!broadcastId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Broadcast Recipients", query);
  return query;
}

export function channelsWithPermissionsSelect({
  workspaceId,
  workspaceMembershipId,
}: { workspaceId?: string; workspaceMembershipId?: string }) {
  const query = db
    .select({
      ...getTableColumns(feed),
      permission_name: sql`${permission.name} as permission_name`,
      wsmid: sql`${permission.workspaceMembershipId} as wsmid`,
      name: account.name,
      email: account.email,
    })
    .from(feed)
    .innerJoin(permission, eq(permission.feedId, feed.id))
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .innerJoin(account, eq(account.id, workspaceMembership.accountId))
    .where(
      and(
        eq(feed.workspaceId, workspaceId),
        eq(permission.name, "write"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
      ),
    )
    // .groupBy(feed.id)
    .orderBy(asc(feed.isDm), asc(feed.title));

  if (!workspaceId || !workspaceMembershipId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Channels with Permissions", query);
  return query;
}

export function broadcastActionForWorkflowItemIdSelect({
  workflowItemId,
}: {
  workflowItemId?: string;
}) {
  const query = db
    .select()
    .from(broadcastAction)
    .where(eq(broadcastAction.workflowItemId, workflowItemId));

  if (!workflowItemId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Broadcast Action for Workflow Item Id", query);
  return query;
}

export function scheduleTriggerForBroadcastActionIdSelect({
  broadcastActionId,
}: {
  broadcastActionId?: string;
}) {
  const query = db
    .select()
    .from(scheduleTrigger)
    .where(
      and(
        eq(scheduleTrigger.broadcastActionId, broadcastActionId),
        isNotNull(scheduleTrigger.cronSchedule),
      ),
    );

  if (!broadcastActionId || allEmpty) {
    logger(["EMPTY QUERY FOR", query.toSQL()]);
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Schedule Trigger for Workflow Item Id", query);
  return query;
}
