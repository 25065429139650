import { db } from "@/db/db";
import {
  account,
  accountEvent,
  audioEncoding,
  broadcastAction,
  broadcastRecipient,
  deviceRegistration,
  directWsInvitation,
  displayArtifact,
  feed,
  feedGroup,
  feedGroupMembership,
  file,
  item,
  link,
  permission,
  pipelineArtifactMetadata,
  publishedWorkflowItem,
  scheduleTrigger,
  template,
  transcription,
  workflowItem,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
  wsHandsFreeStatus,
} from "@/db/schema";
import {
  Account,
  AccountEvent,
  AudioEncoding,
  DirectWsInvitation,
  DisplayArtifact,
  Item,
  PipelineArtifactMetadata,
  Transcription,
  WorkspaceCommandAlias,
} from "@/db/types";
import {
  DeviceRegistration,
  HandsFreeStatusForMember,
  UserInfoResponse,
  Workspace,
  WorkspaceMembership,
  WsAccount,
  WsAudioEncoding,
  WsBroadcastAction,
  WsBroadcastRecipient,
  WsCommandAlias,
  WsDisplayArtifact,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsFile,
  WsItem,
  WsLink,
  WsPAM,
  WsPermission,
  WsPublishedWorkflowItem,
  WsScheduleTrigger,
  WsTemplate,
  WsTranscription,
  WsWorkflowItem,
  DirectWorkspaceInvitation as WsDirectWorkspaceInvitation,
} from "web-client/api/data-contracts";
import { updateWorkspaceConfig } from "../workspaceConfig";
import { unreadClearEvents } from "@/models/UnreadsContextProvider";
import { eq, and, ne, inArray, or, desc, getTableColumns } from "drizzle-orm";
import { logger } from "@/utils/logging";

export async function upsertMyAccount(userInfo: UserInfoResponse) {
  const accountId =
    userInfo?.session?.credentials?.[0].CredentialScopes?.[0].accountId;

  if (!accountId) {
    logger(["No account id found in userInfo", userInfo], true);
    return;
  }

  const data: Account = {
    id: accountId,
    mine: true,
    preferredLanguage: null,
    name: userInfo?.name,
    accountType: userInfo?.accountType,
    avatarColor: userInfo?.avatarColor,
    email: userInfo?.email,

    firstName: null,
    lastName: null,
    phoneNumber: null,
    emailVerified: false,
    phoneNumberVerified: false,
  };

  const results = await db
    .insert(account)
    .values(data)
    .onConflictDoUpdate({
      target: account.id,
      set: {
        mine: true,
        name: userInfo?.name,
        accountType: userInfo?.accountType,
        avatarColor: userInfo?.avatarColor,
        email: userInfo?.email,
      },
    })
    .returning({
      ...getTableColumns(account),
    })
    .execute();

  logger(["Upserted account", results]);

  return results;
}

export async function upsertDeviceRegistration(
  deviceRegistrationRecord: DeviceRegistration,
) {
  const deviceRegistrationId = localStorage.getItem("deviceRegistrationId");
  // save to local storage on upsert
  if (deviceRegistrationId !== deviceRegistrationRecord?.id) {
    localStorage.setItem(
      "deviceRegistrationId",
      `"${deviceRegistrationRecord?.id}"`,
    );
  }

  return db
    .insert(deviceRegistration)
    .values(deviceRegistrationRecord)
    .onConflictDoUpdate({
      target: deviceRegistration.id,
      set: deviceRegistrationRecord,
    });
}

export async function upsertWorkspace(workspaceRecord: Workspace) {
  updateWorkspaceConfig(workspaceRecord);
  return db.insert(workspace).values(workspaceRecord).onConflictDoUpdate({
    target: workspace.id,
    set: workspaceRecord,
  });
}

export async function upsertWsFeed(feedRecord: WsFeed) {
  return db.insert(feed).values(feedRecord).onConflictDoUpdate({
    target: feed.id,
    set: feedRecord,
  });
}

export async function upsertWsFeedGroup(feedGroupRecord: WsFeedGroup) {
  return db.insert(feedGroup).values(feedGroupRecord).onConflictDoUpdate({
    target: feedGroup.id,
    set: feedGroupRecord,
  });
}

export async function upsertWsFeedGroupMembership(
  feedGroupMembershipRecord: WsFeedGroupMembership,
) {
  return db
    .insert(feedGroupMembership)
    .values(feedGroupMembershipRecord)
    .onConflictDoUpdate({
      target: feedGroupMembership.id,
      set: feedGroupMembershipRecord,
    });
}

export async function upsertWorkspaceMembership(
  workspaceMembershipRecord: WorkspaceMembership,
) {
  return db
    .insert(workspaceMembership)
    .values(workspaceMembershipRecord)
    .onConflictDoUpdate({
      target: workspaceMembership.id,
      set: workspaceMembershipRecord,
    })
    .execute();
}

export async function upsertWsPermission(permissionRecord: WsPermission) {
  return db.insert(permission).values(permissionRecord).onConflictDoUpdate({
    target: workspaceMembership.id,
    set: permissionRecord,
  });
}
export async function upsertWsAccount(accountRecord: WsAccount) {
  return db.insert(account).values(accountRecord).onConflictDoUpdate({
    target: account.id,
    set: accountRecord,
  });
}

export async function upsertWsItem(itemRecord: WsItem) {
  const data: Item = {
    id: itemRecord.id,
    feedId: itemRecord.feedId,
    accountId: itemRecord.accountId,
    contentId: itemRecord.contentId,
    createdAt: itemRecord.createdAt,
    deletedAt: itemRecord.deletedAt,
    groupId: itemRecord.groupId,
    isSilent: itemRecord.isSilent,
    loadedContent: false,
    status: null,
    unread: null,
  };

  return db.insert(item).values(data).onConflictDoUpdate({
    target: feed.id,
    set: itemRecord,
  });
}

export async function upsertWsDisplayArtifact(
  displayRecord: WsDisplayArtifact,
) {
  const data: DisplayArtifact = {
    id: displayRecord.id,
    contentId: displayRecord.contentId,
    createdAt: displayRecord.createdAt,
    deletedAt: displayRecord.deletedAt,
    description: displayRecord.description,
    title: displayRecord.title,
  };

  return db.insert(displayArtifact).values(data).onConflictDoUpdate({
    target: feed.id,
    set: displayRecord,
  });
}

export async function upsertWsTranscription(
  transcriptionRecord: WsTranscription,
) {
  const data: Transcription = {
    id: transcriptionRecord.id,
    createdAt: transcriptionRecord.createdAt,
    contentId: transcriptionRecord.contentId,
    transcriptionContent: transcriptionRecord.transcription,
    transcriptionType: transcriptionRecord.transcriptionType,
    backendModel: transcriptionRecord.model,
    confidence: transcriptionRecord.confidence?.toString(),
    executionTime: transcriptionRecord.executionTime?.toString(),
    language: transcriptionRecord.language,
    priority: transcriptionRecord.priority,
    url: transcriptionRecord.url,
    translatedFrom: transcriptionRecord.translatedFrom,
    format: transcriptionRecord.format,
  };

  return db.insert(transcription).values(data).onConflictDoUpdate({
    target: transcription.id,
    set: transcriptionRecord,
  });
}

export async function upsertWsAudioEncoding(
  audioEncodingRecord: WsAudioEncoding,
) {
  const data: AudioEncoding = {
    id: audioEncodingRecord.id,
    contentId: audioEncodingRecord.contentId,
    createdAt: audioEncodingRecord.createdAt,
    codec: audioEncodingRecord.codec,
    duration: audioEncodingRecord.duration.toString(),
    generatedService: audioEncodingRecord.generatedService,
    url: audioEncodingRecord.url,
    generatedVoice: audioEncodingRecord.generatedVoice,
    language: audioEncodingRecord.language,
    mimeType: audioEncodingRecord.mimeType,
    priority: audioEncodingRecord.priority,
    transcriptionId: audioEncodingRecord.transcriptionId,
    transcriptionType: audioEncodingRecord.transcriptionType,
    translatedFrom: audioEncodingRecord.translatedFrom,
  };
  return db.insert(audioEncoding).values(data).onConflictDoUpdate({
    target: audioEncoding.id,
    set: data,
  });
}

export async function upsertWsLink(linkRecord: WsLink) {
  return db.insert(link).values(linkRecord).onConflictDoUpdate({
    target: link.id,
    set: linkRecord,
  });
}

export async function upsertWsFile(fileRecord: WsFile) {
  return db.insert(file).values(fileRecord).onConflictDoUpdate({
    target: link.id,
    set: fileRecord,
  });
}

export async function upsertWsEvent(eventRecord: WsEvent) {
  const data: AccountEvent = {
    id: eventRecord.id,
    accountId: eventRecord.accountId,
    createdAt: eventRecord.createdAt,
    contentId: eventRecord.contentId,
    feedId: eventRecord.feedId,
    itemId: eventRecord.itemId,
    name: eventRecord.name,
  };
  return db.insert(accountEvent).values(data).onConflictDoUpdate({
    target: accountEvent.id,
    set: eventRecord,
  });
}

export async function upsertDirectWsInvitation(
  directWsInvitationRecord: WsDirectWorkspaceInvitation,
) {
  return db
    .insert(directWsInvitation)
    .values(directWsInvitationRecord)
    .onConflictDoUpdate({
      target: accountEvent.id,
      set: directWsInvitationRecord,
    });
}

export async function upsertWsScheduleTrigger(
  scheduleTriggerRecord: WsScheduleTrigger,
) {
  // force true to be an integer until migrations can be fixed
  const scheduleTriggerRecordAsInteger = {
    ...scheduleTriggerRecord,
    enabled: scheduleTriggerRecord?.enabled ? 1 : (0 as number),
  };

  return db
    .insert(scheduleTrigger)
    .values(scheduleTriggerRecordAsInteger)
    .onConflictDoUpdate({
      target: accountEvent.id,
      set: scheduleTriggerRecordAsInteger,
    });
}

export async function upsertWsBroadcastAction(
  broadcastActionRecord: WsBroadcastAction,
) {
  return db
    .insert(broadcastAction)
    .values(broadcastActionRecord)
    .onConflictDoUpdate({
      target: accountEvent.id,
      set: broadcastActionRecord,
    });
}

export async function upsertWsDraft(draftRecord: WsWorkflowItem) {
  return db.insert(workflowItem).values(draftRecord).onConflictDoUpdate({
    target: publishedWorkflowItem.id,
    set: draftRecord,
  });
}

export async function upsertWsPublishedDraft(
  publishedDraftRecord: WsPublishedWorkflowItem,
) {
  return db
    .insert(publishedWorkflowItem)
    .values(publishedDraftRecord)
    .onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: publishedDraftRecord,
    });
}

export async function upsertWsBroadcastRecipient(
  broadcastRecipientRecord: WsBroadcastRecipient,
) {
  return db
    .insert(broadcastRecipient)
    .values(broadcastRecipientRecord)
    .onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: broadcastRecipientRecord,
    });
}

export async function upsertWsTemplate(templateRecord: WsTemplate) {
  return db.insert(template).values(templateRecord).onConflictDoUpdate({
    target: publishedWorkflowItem.id,
    set: templateRecord,
  });
}

export async function upsertWsHandsFreeStatus(handsFreeStatusRecord: {
  id: string;
  enabled: boolean;
  timestamp: string;
}) {
  return db
    .insert(wsHandsFreeStatus)
    .values(handsFreeStatusRecord)
    .onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: handsFreeStatusRecord,
    });
}

export async function upsertWsPAM(pamRecord: WsPAM) {
  const data: PipelineArtifactMetadata = {
    id: pamRecord.id,
    createdAt: pamRecord.createdAt,
    contentId: pamRecord.contentId,
    updatedAt: pamRecord.updatedAt,
    vadResult: null,
  };
  return db.insert(pipelineArtifactMetadata).values(data).onConflictDoUpdate({
    target: pipelineArtifactMetadata.id,
    set: pamRecord,
  });
}

export async function upsertWsCommandAlias(commandAliasRecord: WsCommandAlias) {
  const data: WorkspaceCommandAlias = {
    id: commandAliasRecord.id,
    workspaceId: commandAliasRecord.workspaceId,
    alias: commandAliasRecord.alias,
    createdAt: commandAliasRecord.createdAt,
    workspaceMembershipId: commandAliasRecord.workspaceMembershipId,
    feedId: commandAliasRecord.feedId,
  };

  return db.insert(workspaceCommandAlias).values(data).onConflictDoUpdate({
    target: workspaceCommandAlias.id,
    set: commandAliasRecord,
  });
}

export async function updateUnreadItemByEvents(
  event: WsEvent,
  myAccountId: string,
  myCurrentWorkspaceRole: string,
  workspaceId: string,
) {
  const events = unreadClearEvents;

  const isClearEvent =
    events.includes(event.name) && (event.feedId || event.itemId);
  if (isClearEvent) {
    const commandAliases = await db.query.workspaceCommandAlias
      .findMany({
        where: eq(workspaceCommandAlias.feedId, event.feedId),
      })
      .execute();
    const isAliasChannel = commandAliases?.length > 0;
    const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;
    const otherOrganizers = await db
      .select({ accountId: workspaceMembership.accountId })
      .from(workspaceMembership)
      .where(
        and(
          eq(workspaceMembership.workspaceId, workspaceId),
          eq(workspaceMembership.role, "member"),
          ne(workspaceMembership.accountId, myAccountId),
        ),
      )
      .execute();

    const mappedOrganizers = otherOrganizers?.map(
      (organizer) => organizer?.accountId,
    );

    const isOrganizerEvent = mappedOrganizers?.includes(event?.accountId);
    let itemsToUpdate = [];
    if (event?.accountId === myAccountId || (isOrganizer && isOrganizerEvent)) {
      if (event?.itemId) {
        itemsToUpdate.push(event?.itemId);
      } else {
        const unreadItems = await db.query.item
          .findMany({
            where: and(eq(item.feedId, event.feedId), eq(item.unread, true)),
          })
          .execute();
        itemsToUpdate = unreadItems?.map((item) => item?.id);
      }
    }

    if (itemsToUpdate?.length > 0) {
      console.log("UNREAD: itemsToUpdate", itemsToUpdate);
      return await db
        .update(item)
        .set({ unread: false })
        .where(inArray(item.id, itemsToUpdate))
        .execute();
    }
  }
  return false;
}

export async function updateUnreadItemByFeedId(
  itemRecord: WsItem,
  myAccountId: string,
  myCurrentWorkspaceRole: string,
  workspaceId: string,
) {
  const checkedItem = await db.query.item.findFirst({
    where: eq(item.id, itemRecord.id),
  });

  if (!checkedItem) return false;
  logger(["checkedItem", checkedItem?.unread]);

  const feedId = checkedItem?.feedId;
  const commandAliases = await db.query.workspaceCommandAlias
    .findMany({
      where: eq(workspaceCommandAlias.feedId, itemRecord.feedId),
    })
    .execute();
  const isAliasChannel = commandAliases?.length > 0;

  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;

  const otherOrganizers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "member"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const otherMembers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "limitedMember"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const mappedOrganizers = new Map(
    otherOrganizers?.map((organizer) => [organizer?.accountId, true]),
  );
  const mappedMembers = new Map(
    otherMembers?.map((member) => [member.accountId, true]),
  );

  const feedReadPermission = await db.query.permission
    .findFirst({
      where: and(
        eq(permission.feedId, feedId),
        eq(permission.accountId, myAccountId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    })
    .execute();
  let shouldMarkItemAsUnread = false;
  // Rules for marking items as unread
  // Organizer
  // 1. If the user is an organizer and the feed is an alias channel, mark all items as unread from members only
  // 2. If the user is an organizer and the feed is not an alias channel, mark all items as unread

  // Admin
  // 1. If the user is an admin and the feed is an alias channel or non-alias channel, mark all items as unread

  // Member
  // 1. If the user is a member and the feed is an alias channel or non-alias channel, mark all items as unread

  if (isOrganizer && isAliasChannel) {
    mappedOrganizers.set(myAccountId, true);
    const event = await db.query.accountEvent
      .findFirst({
        where: and(
          or(
            eq(accountEvent.feedId, feedId),
            eq(accountEvent.itemId, checkedItem.id),
          ),
          inArray(accountEvent.accountId, [...mappedOrganizers.keys()]),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const eventDate = !event
      ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || ""
      : event?.createdAt;
    shouldMarkItemAsUnread =
      itemRecord.accountId !== myAccountId &&
      mappedMembers.has(itemRecord.accountId) &&
      eventDate <= itemRecord?.createdAt;
  } else {
    const event = await db.query.accountEvent
      .findFirst({
        where: and(
          or(
            eq(accountEvent.feedId, feedId),
            eq(accountEvent.itemId, checkedItem.id),
          ),
          eq(accountEvent.accountId, myAccountId),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const eventDate = !event
      ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || ""
      : event?.createdAt;
    shouldMarkItemAsUnread =
      itemRecord.accountId !== myAccountId &&
      eventDate <= itemRecord?.createdAt;
  }

  if (shouldMarkItemAsUnread) {
    console.log("UNREAD: marking as unread", checkedItem);
    return await db
      .update(item)
      .set({ unread: true })
      .where(eq(item.id, checkedItem.id))
      .execute();
  }
  return false;
}

export async function upsertManyWsPermission(wsPermissions: WsPermission[]) {
  wsPermissions.map((permissionRecord) => upsertWsPermission(permissionRecord));
}

export async function deleteWsDraft(draftId: string) {
  return db.delete(workflowItem).where(eq(workflowItem.id, draftId));
}
