import {
  Workspace,
  WorkspaceMembership,
  WsAccount,
  WsAudioEncoding,
  WsCommandAlias,
  WsDisplayArtifact,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsFile,
  WsItem,
  WsLink,
  WsPAM,
  WsPermission,
  WsTemplate,
  WsTranscription,
} from "web-client/api/data-contracts";

import {
  AudioEncoding,
  DisplayArtifact,
  PipelineArtifactMetadata,
  Transcription,
  Workspace as WorkspaceType,
} from "@/db/types";
import { chunkWithMinSize } from "@/utils";
import { db } from "../../db/db";
import {
  account,
  accountEvent,
  audioEncoding,
  displayArtifact,
  feed,
  feedGroup,
  feedGroupMembership,
  file,
  item,
  link,
  permission,
  pipelineArtifactMetadata,
  template,
  transcription,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
} from "../../db/schema";

const pgEnabled = true;

export async function createManyWsItem(records: WsItem[]) {
  if (records.length === 0) return;
  const data = records.map((itemRecord) => ({
    id: itemRecord.id,
    createdAt: itemRecord.createdAt,
    feedId: itemRecord.feedId,
    accountId: itemRecord.accountId,
    contentId: itemRecord?.contentId ?? "", // force a value for contentId if null
    deletedAt: null,
    groupId: itemRecord.groupId || null,
    loadedContent: false,
    isSilent: itemRecord.isSilent,
    unread: null,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db.insert(item).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.log({ item });
      console.error("Error creating WsItems", e);
    }
  }
  return;
}

export async function createManyWorkspaceMembership(
  records: WorkspaceMembership[],
) {
  if (records.length === 0) return;
  const data = records.map((workspaceMembershipRecord) => ({
    id: workspaceMembershipRecord.id,
    workspaceId: workspaceMembershipRecord.workspaceId,
    accountId: workspaceMembershipRecord.accountId,
    status: workspaceMembershipRecord.status,
    role: workspaceMembershipRecord.role,
  }));

  try {
    return await db
      .insert(workspaceMembership)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating workspace memberships", e);
  }
}

export async function createManyWsTemplate(records: WsTemplate[]) {
  if (records.length === 0) return;
  const data = records.map((c) => ({
    id: c.id,
    name: c.name || "",
    template: c.template || "",
    workspaceId: c.workspaceId,
    authorId: c.authorId || null,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    deletedAt: c.deletedAt || null,
  }));
  try {
    return await db
      .insert(template)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating templates", e);
  }
}

export async function createManyWsAccount(records: WsAccount[]) {
  if (records.length === 0) return;
  const data = records.map((accountRecord) => ({
    id: accountRecord.id,
    name: accountRecord.name || null,
    accountType: accountRecord.accountType || null,
    avatarColor: accountRecord.avatarColor || null,
    firstName: accountRecord.firstName || null,
    lastName: accountRecord.lastName || null,
    email: accountRecord.email || null,
    emailVerified: accountRecord.emailVerified,
    phoneNumber: accountRecord.phoneNumber || null,
    phoneNumberVerified: accountRecord.phoneNumberVerified,
  }));

  try {
    return await db
      .insert(account)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating accounts", e);
  }
}

export async function createManyWsFeedGroup(records: WsFeedGroup[]) {
  if (records.length === 0) return;
  const data = records.map((feedGroupRecord) => ({
    id: feedGroupRecord.id,
    createdAt: feedGroupRecord.createdAt,
    updatedAt: feedGroupRecord?.updatedAt || "",
    workspaceId: feedGroupRecord.workspaceId,
    name: feedGroupRecord.name,
  }));

  try {
    return await db
      .insert(feedGroup)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating feed groups", e);
  }
}

export async function createManyWsFeedGroupMembership(
  records: WsFeedGroupMembership[],
) {
  if (records.length === 0) return;
  const data = records.map((membershipRecord) => ({
    id: membershipRecord.id,
    createdAt: membershipRecord.createdAt,
    updatedAt: membershipRecord?.updatedAt || "",
    groupId: membershipRecord.groupId,
    feedId: membershipRecord.feedId,
    workspaceId: membershipRecord.workspaceId,
  }));

  try {
    return await db
      .insert(feedGroupMembership)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating feed group memberships", e);
  }
}

export async function createManyWsCommandAliases(records: WsCommandAlias[]) {
  if (records.length === 0) return;
  const data = records.map((c) => ({
    id: c.id,
    alias: c.alias,
    workspaceId: c.workspaceId,
    workspaceMembershipId: c.workspaceMembershipId,
    feedId: c.feedId,
    createdAt: c.createdAt,
  }));

  try {
    return await db
      .insert(workspaceCommandAlias)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating command aliases", e);
  }
}

export async function createManyWsEvent(records: WsEvent[]) {
  if (records.length === 0) return;
  const data = records.map((eventRecord) => ({
    id: eventRecord.id,
    accountId: eventRecord.accountId,
    createdAt: eventRecord.createdAt,
    name: eventRecord.name,
    contentId: eventRecord.contentId || null,
    feedId: eventRecord.feedId || null,
    itemId: eventRecord.itemId || null,
  }));

  try {
    return await db
      .insert(accountEvent)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating events", e);
  }
}

export async function createManyWsPermission(records: WsPermission[]) {
  if (records.length === 0) return;
  const data = records.map((p) => ({
    id: p.id,
    workspaceMembershipId: p.workspaceMembershipId,
    feedId: p.feedId,
    workflowItemId: p.workflowItemId,
    name: p.name,
    enabled: p.enabled,
    createdAt: p.createdAt,
    updatedAt: p.updatedAt,
  }));

  const chunkedData = chunkWithMinSize(data, 2000);
  for (const chunk of chunkedData) {
    try {
      await db.insert(permission).values(chunk).onConflictDoNothing().execute();
    } catch (e) {
      console.error("Error creating permissions chunk", e);
    }
  }
  return;
}

export async function createManyWsFeed(records: WsFeed[]) {
  if (records.length === 0) return;
  const data = records.map((feedRecord) => ({
    id: feedRecord.id,
    createdAt: feedRecord.createdAt,
    updatedAt: feedRecord.updatedAt,
    workspaceId: feedRecord.workspaceId,
    readOnly: feedRecord.readOnly,
    lastOpened: feedRecord.lastOpened || null,
    latestActivity: feedRecord.latestActivity || null,
    loadedFirstPage: false,
    loadedLastPage: false,
    loadedEvents: false,
    loadedPermissions: false,
    feedType: feedRecord.feedType,
    title: feedRecord.title || null,
    isPrivate: feedRecord.isPrivate,
    autoJoin: feedRecord?.autoJoin,
    isDm: feedRecord.isDm,
    isSilent: feedRecord?.isSilent,
  }));

  try {
    return await db.insert(feed).values(data).onConflictDoNothing().execute();
  } catch (e) {
    console.error("Error creating feeds", e);
  }
}

export async function createManyWorkspace(records: Workspace[]) {
  if (records.length === 0) return;
  const data: WorkspaceType[] = records.map((workspaceRecord) => ({
    id: workspaceRecord.id,
    name: workspaceRecord.name,
    createdAt: workspaceRecord.createdAt,
    updatedAt: workspaceRecord.updatedAt,
  }));

  try {
    return await db
      .insert(workspace)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating workspaces", e);
  }
}

export async function createManyWsTranscription(records: WsTranscription[]) {
  if (records.length === 0) return;
  const data: Transcription[] = records.map((transcriptionRecord) => ({
    id: transcriptionRecord.id,
    createdAt: transcriptionRecord.createdAt,
    contentId: transcriptionRecord.contentId,
    transcriptionContent: transcriptionRecord.transcription,
    transcriptionType: transcriptionRecord.transcriptionType,
    backendModel: transcriptionRecord.model,
    confidence: transcriptionRecord.confidence?.toString(),
    executionTime: transcriptionRecord.executionTime?.toString(),
    language: transcriptionRecord.language,
    priority: transcriptionRecord.priority,
    url: transcriptionRecord.url,
    translatedFrom: transcriptionRecord.translatedFrom,
    format: transcriptionRecord.format,
  }));

  try {
    return await db.transaction(async (db) => {
      db.setTransaction({
        isolationLevel: "repeatable read",
      });
      return await db
        .insert(transcription)
        .values(data)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating transcriptions", e);
  }
}

export async function createManyWsAudioEncoding(records: WsAudioEncoding[]) {
  if (records.length === 0) return;
  const data: AudioEncoding[] = records.map((audioEncodingRecord) => ({
    id: audioEncodingRecord.id,
    contentId: audioEncodingRecord.contentId,
    createdAt: audioEncodingRecord.createdAt,
    codec: audioEncodingRecord.codec,
    duration: audioEncodingRecord.duration.toString(),
    generatedService: audioEncodingRecord.generatedService,
    url: audioEncodingRecord.url,
    generatedVoice: audioEncodingRecord.generatedVoice,
    language: audioEncodingRecord.language,
    mimeType: audioEncodingRecord.mimeType,
    priority: audioEncodingRecord.priority,
    transcriptionId: audioEncodingRecord.transcriptionId,
    transcriptionType: audioEncodingRecord.transcriptionType,
    translatedFrom: audioEncodingRecord.translatedFrom,
  }));

  try {
    return await db.transaction(async (db) => {
      db.setTransaction({
        isolationLevel: "repeatable read",
      });
      return await db
        .insert(audioEncoding)
        .values(data)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating audio encodings", e);
  }
}

export async function createManyWsFile(records: WsFile[]) {
  if (records.length === 0) return;
  try {
    return await db.transaction(async (db) => {
      db.setTransaction({
        isolationLevel: "repeatable read",
      });
      return await db
        .insert(file)
        .values(records)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating files", e);
  }
}

export async function createManyWsLink(records: WsLink[]) {
  if (records.length === 0) return;
  try {
    return await db.transaction(async (db) => {
      db.setTransaction({
        isolationLevel: "repeatable read",
      });
      return await db
        .insert(link)
        .values(records)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating links", e);
  }
}

export async function createManyWsDisplayArtifact(
  records: WsDisplayArtifact[],
) {
  if (records.length === 0) return;
  const data: DisplayArtifact[] = records.map((displayRecord) => ({
    id: displayRecord.id,
    contentId: displayRecord.contentId,
    createdAt: displayRecord.createdAt,
    deletedAt: displayRecord.deletedAt,
    description: displayRecord.description,
    title: displayRecord.title,
  }));

  try {
    return await db.transaction(async (db) => {
      db.setTransaction({
        isolationLevel: "repeatable read",
      });
      return await db
        .insert(displayArtifact)
        .values(data)
        .onConflictDoNothing()
        .execute();
    });
  } catch (e) {
    console.error("Error creating display artifacts", e);
  }
}

export async function createManyWsPAM(records: WsPAM[]) {
  if (records.length === 0) return;

  const data: PipelineArtifactMetadata[] = records.map((pamRecord) => ({
    id: pamRecord.id,
    contentId: pamRecord.contentId,
    createdAt: pamRecord.createdAt,
    updatedAt: pamRecord.updatedAt,
    vadResult: null,
  }));
  try {
    return await db
      .insert(pipelineArtifactMetadata)
      .values(data)
      .onConflictDoNothing()
      .execute();
  } catch (e) {
    console.error("Error creating pipeline artifact metadata", e);
  }
}
