import { Box, Tooltip } from "@mui/material";
import { useRef } from "react";

export default function ChannelTypes({
  activeChannelType,
  changeChannelType,
  unreadCount,
  limitedMember,
}: {
  activeChannelType: string;
  changeChannelType: (value: string) => void;
  unreadCount: number;
  limitedMember: boolean;
}) {
  const channelTypesRef = useRef<HTMLUListElement>();
  const types = [
    {
      text: "My Channels",
      key: "my-channels",
      toolTip: "You are a member of these channels",
      indicator: false,
      hideLimitedMember: false,
      disabled: false,
    },
    {
      text: "My Unreads",
      key: "my-unreads",
      toolTip: null,
      indicator: true,
      hideLimitedMember: false,
      disabled: false,
    },
    {
      text: "All Channels",
      key: "all-channels",
      toolTip: null,
      indicator: false,
      hideLimitedMember: true,
      disabled: false,
    },
    {
      text: "All Unreads",
      key: "all-unreads",
      toolTip: null,
      indicator: false,
      hideLimitedMember: true,
      disabled: true,
    },
  ]
    .filter((type) => {
      if (limitedMember || type.disabled) {
        return !type.hideLimitedMember;
      }
      return true;
    })
    .map((type) => ({
      ...type,
      active: type.key === activeChannelType,
    }));

  // dynamically calculate the width of the active item and position appropriately
  const paddingOffset = 8;
  const firstItem = channelTypesRef?.current?.children[0] as HTMLLIElement;
  const itemHeight = firstItem?.offsetHeight;
  const width = channelTypesRef?.current?.offsetWidth;
  const tabWidth = width / types?.length - paddingOffset;
  const activeIndex = types?.findIndex((type) => type?.active);
  const left = activeIndex * tabWidth + paddingOffset * activeIndex;

  return (
    <Box>
      <Box
        ref={channelTypesRef}
        component="ul"
        className="channel-types space-x-0.5"
        sx={{ display: "flex", width: "100%", marginBottom:`-${itemHeight + 8}px` }}
      >
        {types.map((type) => (
          <Tooltip title={type.toolTip} key={type.key}>
            <Box component="li">
              <button
                type="button"
                className={`${type.active ? "active" : ""}`}
                onClick={() => changeChannelType(type.key)}
              >
                <span>{type.text}</span>
                {type.indicator && unreadCount > 0 && (
                  <span
                    className={`indicator ${
                      unreadCount > 0 ? "active" : "in-active"
                    }`}
                  />
                )}
              </button>
            </Box>
          </Tooltip>
        ))}
      </Box>
      <Box className="channel-active-item" sx={{marginLeft: `${left}px`}}>
        <Box sx={{width: tabWidth, height:`${itemHeight}px`, borderRadius:'0.5rem'}} className="active-item" />
      </Box>
    </Box>
  );
}
