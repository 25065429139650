import AvatarWithStatus from "@/components/AvatarWithStatus";
import MemberDrawerListItem from "@/components/MemberDrawerListItem";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import UseTimeouts from "@/hooks/useTimeouts";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { mapForHasWorkspaceMembershipIdMany } from "@/models/caching";
import { feedPermissionsQuery } from "@/models/commonQueries";
import { defaultFocusStyles, menuItemFocusSX } from "@/utils";
import * as Icons from "@mui/icons-material";
import {
  Box,
  DrawerProps,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { throttle } from "throttle-typescript";
import "../assets/menus.css";
import { DrawerHeader } from "./DrawerHeader";

export interface MembersDrawerProps extends DrawerProps {
  handleClose: () => void;
  url: string | null;
}

export default function MembersDrawerContents({
  sx,
  handleClose,
}: MembersDrawerProps) {
  const { fetchWorkspaceHandsFreeStatus } = useContext(DataContext);
  const { myAccount } = useContext(MyAccountContext);
  const { currentWorkspaceId } = useContext(WorkspaceContext);
  const {
    currentFeedId,
    isCurrentFeedAdmin,
    currentFeedPendingInvites,
    currentFeed,
  } = useContext(CurrentFeedContext);
  const { workspaceMembershipMap } = useContext(WorkspaceContext);

  const { handsFreeStatusIndicator } = useFlags();
  const handsFreeAllowed = handsFreeEnabledForWorkspace(currentWorkspaceId);

  const handsFreeRefresh = UseTimeouts(65);
  useEffect(() => {
    if (!handsFreeAllowed || !handsFreeStatusIndicator) {
      return;
    } else if (handsFreeRefresh?.refresh === true) {
      fetchWorkspaceHandsFreeStatus();
    }
  }, [
    handsFreeAllowed,
    handsFreeStatusIndicator,
    handsFreeRefresh?.refresh,
    fetchWorkspaceHandsFreeStatus,
  ]);

  const { rows: feedPermissions } = useDrizzleSelect(
    feedPermissionsQuery({
      feedId: currentFeedId,
    }),
  );

  const permissionMap = useMemo(
    () => mapForHasWorkspaceMembershipIdMany(feedPermissions),
    [feedPermissions],
  );

  const readPermissoins = feedPermissions?.filter(
    (perm) => perm?.name === "read" && perm.enabled,
  );

  const mutatedAccounts = useMemo(() => {
    return readPermissoins
      ?.map((permission) => {
        const accountWithStatus = workspaceMembershipMap.get(
          permission.workspaceMembershipId,
        );
        if (!accountWithStatus) {
          return null;
        }
        const perms = permissionMap.get(permission.workspaceMembershipId);
        const isAdmin = !!perms?.find(
          (perm) => perm.name === "admin" && perm.enabled,
        );
        const isMuted = !perms?.find(
          (perm) => perm.name === "write" && perm.enabled,
        );
        return {
          ...accountWithStatus,
          permissions: perms,
          isAdmin,
          isMuted,
        };
      })
      .filter((account) => account !== null)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [readPermissoins, workspaceMembershipMap, permissionMap]);

  const theme = useTheme();

  const isDm = currentFeed?.isDm;

  const [panelId, setPanelId] = useState<string | null>(null);

  const MemberListHeader = ({
    children,
  }: {
    children: React.ReactNode;
  }) => (
    <ListSubheader
      sx={{
        p: 0,
        background: theme.palette.secondary.dark,
        width: "100%",
        zIndex: 10,
      }}
    >
      <ListItem
        sx={{
          color: theme.palette.primary.main,
          borderRadius: "8px",
          padding: 1,
          border: "1.5px solid transparent",
        }}
        component="div"
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="div"
        >
          {children}
        </Typography>
      </ListItem>
    </ListSubheader>
  );

  return (
    <>
      <Box
        sx={{
          height: "100%",
          background: theme.palette.secondary.dark,
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            p: 2,
            pr: 0.75,
            gap: 3.75,
            height: "100%",
          }}
        >
          <List
            key={`${currentWorkspaceId}-${currentFeedId}-members`}
            className="scrollable-content"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              gap: 3.75,
              position: "relative",
              pt: 0,
              "& li": {
                width: "100%",
              },
              "& ul": {
                padding: 0,
                width: "100%",
              },
            }}
          >
            <li
              key={`section-${currentWorkspaceId}-${currentFeedId}-active-members`}
            >
              <ul
                key={`${currentWorkspaceId}-${currentFeedId}-active-members-list`}
              >
                <MemberListHeader>
                  <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                    Active members
                  </Typography>
                </MemberListHeader>
                <Box>
                  {mutatedAccounts?.map((account) => {
                    return (
                      <Box
                        key={account?.id}
                        sx={{
                          display: "flex",
                          width: "100%",
                          position: "relative",
                          mb: 1,
                          p: 1,
                          alignItems: "center",
                        }}
                        className={`${
                          account?.id === panelId && "active-parent"
                        }`}
                      >
                        <MemberDrawerListItem
                          isDm={isDm}
                          account={account}
                          myAccount={myAccount}
                          isCurrentFeedAdmin={isCurrentFeedAdmin}
                          isOpen={panelId === account?.id}
                          setDropdownState={(state) =>
                            setPanelId(() =>
                              state.id === panelId ? null : state.id,
                            )
                          }
                        />
                      </Box>
                    );
                  })}
                </Box>
              </ul>
            </li>
            {currentFeedPendingInvites?.length > 0 && (
              <li
                key={`section-${currentWorkspaceId}-${currentFeedId}-pending-members`}
              >
                <ul
                  style={{ padding: 0 }}
                  key={`${currentWorkspaceId}-${currentFeedId}-pending-members-list`}
                >
                  <MemberListHeader>
                    <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                      Pending members
                    </Typography>
                  </MemberListHeader>
                  <Box>
                    {currentFeedPendingInvites?.map((invite) => {
                      return (
                        <Box
                          key={invite?.id}
                          sx={{
                            display: "flex",
                            width: "100%",
                            position: "relative",
                            mb: 1,
                            p: 1,
                            alignItems: "center",
                          }}
                        >
                          <ListItem
                            className="menu-options-list"
                            key={invite?.id}
                            sx={{
                              p: 1,
                            }}
                          >
                            <ListItemIcon>
                              <AvatarWithStatus accountId={null} />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ flexGrow: 0 }}
                              primaryTypographyProps={{
                                fontWeight: 500,
                              }}
                              className="text-ellipsis"
                            >
                              {invite?.email || invite?.phoneNumber}
                            </ListItemText>
                          </ListItem>
                        </Box>
                      );
                    })}
                  </Box>
                </ul>
              </li>
            )}
          </List>
        </Stack>
      </Box>
    </>
  );
}