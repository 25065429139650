export enum Tags {
  h3 = 'h3',
  // var = "var",
  }

type TypeScriptHTMLTags<T extends Tags> = {
  [P in keyof HTMLElementTagNameMap]: P;
} & {
  [P in T]: never;
};

type HTMLTags = TypeScriptHTMLTags<Tags>;

const createElementFromTag = (tag: Tags) =>
  tag as keyof JSX.IntrinsicElements;
