import { Stack, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import WorkflowScheduledItem from "./WorkflowScheduledItem";
import Pagination from "../Pagination";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import {
  broadcastActionSelect,
  scheduleTriggerSelect,
  workflowItemsSelect,
} from "@/models/commonQueries";

export default function WorkflowItems() {
  const { currentWorkspaceId: workspaceId } = useContext(WorkspaceContext);
  const theme = useTheme();
  const [activePage, setActivePage] = useState(1);
  const [templatesPerPage, setTemplatesPerPage] = useState(20);
  const [date] = useState(new Date(Date.now()).toISOString());

  const { rows: workflowItems } = useDrizzleSelect(
    workflowItemsSelect({
      workspaceId,
    }),
  );

  console.log("date for query", { date });

  const { rows: broadcasts } = useDrizzleSelect(
    broadcastActionSelect({
      workspaceId,
    }),
  );

  const { rows: scheduleTriggers } = useDrizzleSelect(
    scheduleTriggerSelect({
      workspaceId,
      date,
    }),
  );

  const scheduledItems = workflowItems?.filter((wi) => {
    const broadcast = broadcasts?.find((b) => b?.workflowItemId === wi?.id);
    const schedules = scheduleTriggers?.find(
      (ots) => ots?.broadcastActionId === broadcast?.id,
    );

    if (schedules) {
      return wi;
    }
  });

  const handlePerPageChange = (page) => {
    setActivePage(page);
  };

  const handleTemplatesPageChange = (perPage) => {
    setTemplatesPerPage(perPage);
  };

  return (
    <Stack
      sx={{ width: "100%", height: "100%", position: "relative" }}
      className="scrollable-content"
    >
      {scheduledItems?.length > 0 ? (
        <>
          <Stack
            sx={{
              width: "100%",
              height: "auto",
              gap: 3,
              position: "relative",
              "& .scheduled-message-element:last-of-type": {
                marginBottom: "32px",
              },
              flexGrow: 1,
            }}
          >
            {scheduledItems
              ?.slice(
                (activePage - 1) * templatesPerPage,
                activePage * templatesPerPage - 1,
              )
              ?.map((scheduledItem) => (
                <WorkflowScheduledItem
                  key={`${scheduledItem?.id}`}
                  workflowItem={scheduledItem}
                />
              ))}
          </Stack>
          <Stack
            sx={{
              width: "100%",
              position: "sticky",
              bottom: 16,
              alignItems: "center",
            }}
          >
            <Pagination
              itemsCount={scheduledItems?.length || 0}
              onPageChange={handlePerPageChange}
              onRowsPerPageChange={handleTemplatesPageChange}
            />
          </Stack>
        </>
      ) : (
        <Stack
          sx={{
            width: "100%",
            py: 1.5,
            px: 2,
            background: theme.palette.secondary.main,
            borderRadius: 1.5,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>
            No scheduled messages
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
